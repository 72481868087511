import React, {FC, useEffect, useState} from "react";
import {Alert, Avatar, Box, Button, CircularProgress, Container, IconButton, Stack} from "@mui/material";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import SearchInput from "../common/SearchInput";
import PageWithBackLink from "../common/PageWithBackLink";
import Typography from "@mui/material/Typography";
import GroupCreatePage from "./GroupCreatePage";
import {loadContacts} from "../../store/contacts";
import {Group, Groups, Refresh} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../hook";
import {stringToColor} from "../../utils/color";
import GroupDetailedPage from "./GroupDetailedPage";
import {loadGroups} from "../../store/groups";

const GroupsPage: FC = () => {
    const dispatch = useAppDispatch()
    const groups = useAppSelector(state => state.groups.list)

    const navigate = useNavigate();

    const [filter, setFilter] = useState('')

    useEffect(function () {
        if (groups.items.length === 0) {
            dispatch(loadContacts())
        }
    }, [])

    return <Container sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
        maxWidth: '100%',
    }} disableGutters>
        <Routes>
            <Route index element={
                <>
                    <Stack padding={2} spacing={2} direction="row">
                        <SearchInput value={filter} onFilterChange={(filter) => {
                            setFilter(filter)
                        }}/>
                        <Button component={Link} to="create" variant="text" color="primary" sx={{ml: 2}}>
                            Создать
                        </Button>
                    </Stack>
                    <Box sx={{
                        width: '100%',
                        maxHeight: 'inherit',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        boxSizing: 'border-box',
                        height: 'inherit',
                    }}>
                        <Container>
                            {
                                groups.isProcessing &&
                                <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                                    <CircularProgress/>
                                </Box>
                            }
                            {
                                groups.error != null &&
                                <Alert severity="error" action={
                                    <IconButton
                                        aria-label="reload"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            dispatch(loadGroups())
                                        }}
                                    >
                                        <Refresh fontSize="inherit"/>
                                    </IconButton>
                                }>
                                    {groups.error}
                                </Alert>
                            }
                            {
                                !groups.isProcessing && groups.items.length > 0 &&
                                <Stack spacing={2} pb={2}>
                                    {groups.items.map((group) => {
                                        if (filter !== '' && !(group.title ?? '').toUpperCase().includes(filter.toUpperCase())) {
                                            return null
                                        }

                                        return <Stack key={group.id ?? ''} alignItems='center' direction='row'
                                                      spacing={1}
                                                      onClick={() => {
                                                          navigate(group.id ?? '')
                                                      }}
                                        >
                                            <Avatar
                                                sx={{width: 40, height: 40, bgcolor: stringToColor(group.id ?? '')}}>
                                                <Group/>
                                            </Avatar>
                                            <Typography height='100%' overflow='auto' sx={{whiteSpace: 'nowrap'}}
                                                        fontSize='large'>
                                                {group.title}
                                            </Typography>
                                        </Stack>
                                    })}
                                </Stack>
                            }
                        </Container>
                    </Box>
                </>
            }/>
            <Route path="create/*" element={
                <PageWithBackLink
                    backLinkText={"Назад"}
                    backLinkTo={".."}
                    pageTitle={
                        <Typography flexGrow={1} textAlign="center" variant="h6">Создание</Typography>
                    }
                >
                    <GroupCreatePage backLinkTo={".."}/>
                </PageWithBackLink>
            }/>
            <Route path=":id" element={<GroupDetailedPage/>}/>
        </Routes>
    </Container>
}

export default GroupsPage