import React, {FC, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hook";
import {loadBalances} from "../../store/balances";
import {Alert, Box, Button, CircularProgress, Container, IconButton, Stack} from "@mui/material";
import {userName} from "../../utils/user";
import Typography from "@mui/material/Typography";
import SearchInput from "../common/SearchInput";
import {loadContacts} from "../../store/contacts";
import {ReceiptLong, Refresh} from "@mui/icons-material";
import UserBalanceRow from "./UserBalanceRow";
import {Link} from "react-router-dom";

const BalancesPage: FC = () => {
    const dispatch = useAppDispatch()
    const balances = useAppSelector(state => state.balances.list)
    const [filter, setFilter] = useState('')

    useEffect(function () {
        if (balances.items.length === 0) {
            dispatch(loadBalances())
        }
    }, [])

    return <Container sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
        maxWidth: '100%',
    }} disableGutters>
        <Stack padding={2} spacing={2} direction="row">
            <SearchInput value={filter} onFilterChange={(filter) => {
                setFilter(filter)
            }}/>
            <Button startIcon={<ReceiptLong/>}
                // size={'small'}
                    component={Link}
                    to="/receipts/create"
                // variant="outlined"
                    color="primary"
                    sx={{ml: 1}}
            >
                Создать
            </Button>
        </Stack>

        <Box sx={{
            width: '100%',
            maxHeight: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            boxSizing: 'border-box',
            height: 'inherit',
            // mb: 2,
        }}>
            <Container>
                {
                    balances.isProcessing &&
                    <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                        <CircularProgress/>
                    </Box>
                }
                {
                    balances.error != null &&
                    <Alert severity="error" action={
                        <IconButton
                            aria-label="reload"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(loadContacts())
                            }}
                        >
                            <Refresh fontSize="inherit"/>
                        </IconButton>
                    }>
                        {balances.error}
                    </Alert>
                }
                {
                    !balances.isProcessing && balances.items.length > 0 && filter === '' &&
                    <Stack spacing={2} pb={2}>
                        {
                            balances.items.map((balance) => {
                                return <Stack key={balance.currency} spacing={0.5}>
                                    <Typography overflow='auto' sx={{whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                                        {balance?.sum?.amount} {balance?.sum?.currency}
                                    </Typography>
                                    <Stack spacing={0.5}>
                                        {
                                            balance?.userBalances?.map((userBalance) => {
                                                return <UserBalanceRow key={userBalance.user?.userId}
                                                                       userBalance={userBalance}/>
                                            })
                                        }
                                    </Stack>
                                </Stack>

                            })
                        }
                    </Stack>
                }
                {
                    !balances.isProcessing && balances.items.length > 0 && filter !== '' &&
                    <Stack spacing={0.5} pb={2}>
                        {
                            balances.items.map((balance) => {
                                return balance?.userBalances?.map((userBalance) => {
                                    if (!userName(userBalance?.user ?? {}).toUpperCase().includes(filter.toUpperCase())) {
                                        return null
                                    }

                                    return <UserBalanceRow userBalance={userBalance}/>
                                })
                            })
                        }
                    </Stack>
                }
            </Container>
        </Box>
    </Container>
}

export default BalancesPage