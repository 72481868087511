import React, {FC, useEffect, useState} from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Container,
    InputAdornment,
    Stack,
    TextField
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hook";
import UserAvatar from "../common/UserAvatar";
import {ContentCopy, ContentCopyTwoTone, Save} from "@mui/icons-material";
import {GavialChangeMeRequest} from "../../api/Api";
import LoadingButton from "@mui/lab/LoadingButton";
import {loadUser, updateUser} from "../../store/user";

const SettingsPage: FC = () => {
    const dispatch = useAppDispatch()
    const userInfoState = useAppSelector(state => state.user.info)
    const userUpdateState = useAppSelector(state => state.user.update)
    const dicts = useAppSelector(state => state.dicts)

    const [isCopied, setIsCopied] = useState(false)

    const [request, setRequest] = useState<GavialChangeMeRequest>({
        firstName: '',
        lastName: '',
        currency: '',
    })
    useEffect(() => {
        if (userInfoState.loaded) {
            setRequest({
                firstName: userInfoState?.user?.firstName,
                lastName: userInfoState?.user?.lastName,
                currency: userInfoState?.currency ?? '',
            })
        }
    }, [userInfoState.loaded])

    useEffect(function () {
        if (!userInfoState.loaded) {
            dispatch(loadUser())
        }
    }, [])

    return <Container sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
        p: 2,
    }} disableGutters>
        <Stack spacing={2}>
            <Stack width='100%' display='flex' alignItems='center' spacing={2}>
                {
                    userInfoState.isLoading &&
                    <Box flexGrow={1} sx={{display: 'flex'}}>
                        <CircularProgress/>
                    </Box>
                }
                {
                    userInfoState.error !== null &&
                    <Alert sx={{flex: 1}} severity="error">{userInfoState.error}</Alert>
                }
                {
                    !userInfoState.isLoading && userInfoState.user !== null &&
                    <>
                        <Stack spacing={2} width='100%' justifyContent='center'>
                            <UserAvatar id={userInfoState.user.id ?? ''}
                                        firstName={userInfoState.user.firstName}
                                        lastName={userInfoState.user.lastName}
                                        username={userInfoState.user.username}
                                        isReal={userInfoState.user.isReal}
                                        width={50}
                                        height={50}
                            />
                            <Button startIcon={isCopied ? <ContentCopyTwoTone/> : <ContentCopy/>}  variant="text" onClick={() => {
                                const link = `${window.location.host}/contacts/${userInfoState.user?.id}`
                                if (navigator.clipboard) {
                                    navigator.clipboard.writeText(link)
                                } else {
                                    console.log(link)
                                }
                                setIsCopied(true)
                            }}>Скопировать ссылку на профиль</Button>
                            <TextField autoComplete="off" label={'Имя'} value={request.firstName}
                                       disabled={userUpdateState.isProcessing}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                           setRequest({
                                               ...request,
                                               firstName: e.target.value,
                                           })
                                       }}/>
                            <TextField autoComplete="off" label={'Фамилия'} value={request.lastName}
                                       disabled={userUpdateState.isProcessing}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                           setRequest({
                                               ...request,
                                               lastName: e.target.value,
                                           })
                                       }}/>
                            <Autocomplete
                                autoComplete={false}
                                disabled={userUpdateState.isProcessing}
                                disablePortal
                                fullWidth
                                options={dicts.currency.items}
                                value={request.currency}
                                renderInput={(params) => <TextField {...params} label="Валюта по умолчанию"/>}
                                onChange={(e, value) => {
                                    setRequest({
                                        ...request,
                                        currency: value ?? '',
                                    })
                                }}
                            />
                            {
                                (
                                    userInfoState.user.firstName !== request.firstName ||
                                    userInfoState.user.lastName !== request.lastName ||
                                    userInfoState.currency !== request.currency
                                ) &&
                                <>
                                    <LoadingButton loading={userUpdateState.isProcessing}
                                                   // loadingPosition='start'
                                                   color='primary'
                                                   variant="contained" startIcon={<Save/>}
                                                   onClick={() => {
                                                       dispatch(updateUser(request))
                                                   }}>
                                        Сохранить
                                    </LoadingButton>
                                    {
                                        userUpdateState.error !== null &&
                                        <Alert sx={{flex: 1}} severity="error">{userUpdateState.error}</Alert>
                                    }
                                </>
                            }
                        </Stack>
                    </>
                }
            </Stack>
        </Stack>
    </Container>
}

export default SettingsPage
