import React, {FC} from "react";
import {Avatar} from "@mui/material";
import {stringToColor} from "../../utils/color";

interface UserAvatarProps {
    id: string
    firstName: string | null | undefined
    lastName: string | null | undefined
    username: string | null | undefined
    isReal: boolean | null | undefined
    inContactList?: boolean | null | undefined
    width?: any | undefined
    height?: any | undefined
}

const UserAvatar: FC<UserAvatarProps> = (props) => {
    let chars =
        (props.firstName?.charAt(0) ?? "") +
        (props.lastName?.charAt(0) ?? "") +
        (props.username?.charAt(0) ?? "") +
        (props.username?.charAt(1) ?? "") +
        (props.id.charAt(0) ?? "") +
        (props.id.charAt(1) ?? "")

    chars = chars.substring(0, 2)

    // let color = "#969696"
    // if (user.isReal) {
    //     color = stringToColor(id)
    // }

    let color = stringToColor(props.id)
    const width = props.width ?? 40
    const height = props.height ?? 40

    return <Avatar style={{alignSelf: 'center'}} sx={{
        bgcolor: color,
        width: width,
        height: height,
        // border: 2,
        // borderColor: 'black'
    }}>
        {chars}
    </Avatar>
}

export default UserAvatar