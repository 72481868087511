import React, {FC} from "react";
import Typography from "@mui/material/Typography";
import {Box, Checkbox, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {DiscountsType, GavialDiscountInput, GavialPositionInput} from "../../api/Api";
import {DeleteOutline} from "@mui/icons-material";
import {NumericFormat} from "react-number-format";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

interface DiscountEditProps {
    discount: GavialDiscountInput
    currency: string
    positions: GavialPositionInput[]
    onChange: (discount: GavialDiscountInput) => void
    onRemove: (discount: GavialDiscountInput) => void
}


const DiscountEditPanel: FC<DiscountEditProps> = ({discount, currency, positions, onChange, onRemove}) => {
    // const contacts = useAppSelector(state => state.contacts.list)

    // const [consumptionType, setConsumptionType] = React.useState(ConsumptionsType.TYPE_EQUALITY);

    // function onDiscountTypeChange(event: React.MouseEvent<HTMLElement>, value: DiscountsType) {
    //     discount.type = value
    //     onChange(discount)
    // }

    let selected: string[] = []
    if (discount.allPositions) {
        selected = ['all']
    } else {
        discount.positions?.forEach((positionID) => {
            selected.push(positionID)
        })
    }

    let positionIDs: string[] = []
    for (let i = 0; i < positions.length; i++) {
        positionIDs.push(positions[i].id ?? '')
    }

    let showValueExactAmount = discount.paramsExactAmount?.amount ? +discount.paramsExactAmount?.amount / 100 : null
    let showValuePercentages = discount.paramsPercentages?.percentages ? +discount.paramsPercentages?.percentages : null

    if (showValueExactAmount === null && showValuePercentages !== null) {
        showValueExactAmount = showValuePercentages
    }
    if (showValuePercentages === null && showValueExactAmount !== null) {
        showValuePercentages = showValueExactAmount
    }

    return <Stack spacing={2} alignItems="top" direction="row">
        <Typography sx={{mt: 2}}>{discount.index ?? 0}</Typography>
        <Stack spacing={1} flexGrow={1}>
            <Stack flexGrow={1} direction="row" alignItems="center" display="flex" spacing={1}>
                {
                    discount.type === DiscountsType.TYPE_EXACT_AMOUNT &&
                    <NumericFormat
                        inputMode={'decimal'}
                        max={100}
                        min={0}
                        sx={{maxWidth: 150}}
                        customInput={TextField}
                        label='Сколько'
                        autoComplete='off'
                        thousandSeparator=' '
                        allowedDecimalSeparators={['.', ',']}
                        decimalScale={2}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        value={showValueExactAmount}
                        onChange={function (event) {
                            const cents = Math.round(parseFloat(event.target.value.replace(/\s/g, "")) * 100)

                            discount.paramsExactAmount = {amount: cents + ''}
                            discount.paramsPercentages = undefined

                            onChange(discount)
                        }}
                    />
                }
                {
                    discount.type === DiscountsType.TYPE_PERCENTAGES &&
                    <NumericFormat
                        inputMode={'decimal'}
                        max={100}
                        min={0}
                        sx={{maxWidth: 150}}
                        customInput={TextField}
                        label='Сколько'
                        autoComplete='off'
                        thousandSeparator=' '
                        allowedDecimalSeparators={['.', ',']}
                        decimalScale={2}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        value={showValuePercentages}
                        onChange={function (event) {
                            let percentages = +event.target.value

                            discount.paramsPercentages = {percentages: percentages}
                            discount.paramsExactAmount = undefined

                            onChange(discount)
                        }}
                    />
                }
                <FormControl>
                    <InputLabel
                        // id="demo-simple-select-label"
                    >Чего</InputLabel>
                    <Select
                        // labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        sx={{minWidth: 100}}
                        value={discount.type}
                        label="Age"
                        onChange={(e) => {
                            discount.type = e.target.value as DiscountsType

                            if (discount.type === DiscountsType.TYPE_EXACT_AMOUNT) {
                                discount.paramsExactAmount = {amount: (showValueExactAmount ?? 0) * 100 + ''}
                                discount.paramsPercentages = undefined
                            } else if (discount.type === DiscountsType.TYPE_PERCENTAGES) {
                                discount.paramsPercentages = {percentages: showValuePercentages ?? undefined}
                                discount.paramsExactAmount = undefined
                            }

                            onChange(discount)
                        }}
                    >
                        <MenuItem value={DiscountsType.TYPE_PERCENTAGES}>%</MenuItem>
                        <MenuItem value={DiscountsType.TYPE_EXACT_AMOUNT}>{currency}</MenuItem>
                    </Select>
                </FormControl>
                <Box flexGrow={1}></Box>
                <IconButton sx={{m: 0, p: 0}}
                            color='primary'
                            onClick={() => {
                                onRemove(discount)
                            }}
                >
                    <DeleteOutline/>
                </IconButton>
            </Stack>
            <Select multiple
                    value={selected}
                    renderValue={(value) => {
                        if (discount.allPositions) {
                            return 'На все позиции'
                        }

                        let parts: string[] = []
                        positions.forEach((position => {
                            if (selected.indexOf(position.id ?? '') > -1) {
                                parts.push(position.title ?? '')
                            }
                        }))

                        return parts.join(', ')
                    }}
                    onChange={(e) => {
                        const selectedItems = e.target.value

                        if (
                            selectedItems.length === 0 ||
                            (selectedItems.length > 0 && selectedItems.indexOf('all') === selectedItems.length - 1)
                        ) {
                            discount.allPositions = true
                            discount.positions = []
                        } else {
                            discount.allPositions = false
                            discount.positions = []
                            for (let i = 0; i < selectedItems.length; i++) {
                                if (selectedItems[i] === 'all') {
                                    continue
                                }
                                discount.positions.push(selectedItems[i].toString() ?? '')
                            }
                        }

                        onChange(discount)
                    }}>
                <MenuItem value="all">
                    <ListItemIcon>
                        <Checkbox checked={discount.allPositions}/>
                    </ListItemIcon>
                    <ListItemText
                        primary="Все позиции"
                    />
                </MenuItem>
                {
                    positions.map(function (position) {
                        return <MenuItem key={position.index} value={position.id}>
                            <ListItemIcon>
                                <Checkbox checked={selected.indexOf(position.id ?? '') > -1}/>
                            </ListItemIcon>
                            <ListItemText primary={position.index + '. ' + position.title}/>
                        </MenuItem>
                    })
                }
            </Select>
        </Stack>
    </Stack>
}

export default DiscountEditPanel