import {configureStore} from "@reduxjs/toolkit";
import authReducer from './auth'
import userReducer from './user'
import contactsReducer from './contacts'
import groupsReducer from './groups'
import balancesReducer from './balances'
import transfersReducer from './transfers'
import receiptsReducer from './receipts'
import newsReducer from './news'
import dictsReducer from './dicts'

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        contacts: contactsReducer,
        groups: groupsReducer,
        balances: balancesReducer,
        transfers: transfersReducer,
        news: newsReducer,
        dicts: dictsReducer,
        receipts: receiptsReducer,
    },
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch