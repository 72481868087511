import React, {FC} from "react";
import {IconButton, Input} from "@mui/material";
import {Close} from "@mui/icons-material";

interface SearchInputProps {
    onFilterChange: (filter: string) => void
    value?: string | null
}

const SearchInput: FC<SearchInputProps> = ({onFilterChange, value}) => {
    const [filter, setFilter] = React.useState(value ?? '');

    return <Input placeholder="Поиск" inputProps={{autoComplete: "off"}}
           sx={{flexGrow: 1, mr: 0, minHeight: '35px'}}
           value={filter}
           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
               setFilter(e.target.value)
               onFilterChange(e.target.value)
           }}
           endAdornment={(
               <IconButton size='small' sx={{display: filter === '' ? 'none' : 'flex'}} onClick={() => {
                   setFilter('')
                   onFilterChange('')
               }}><Close/></IconButton>)}
    />
}

export default SearchInput