import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    GavialRegisterTransferRequest,
    GavialRegisterTransferResponse,
    GavialTransfer
} from "../api/Api";
import api from "../apiClient/api";
import {updateUser} from "./user";

interface TransferCreationState {
    isProcessing: boolean
    error: string | null
    created: GavialTransfer | null
}

interface TransfersListState {
    loaded: boolean
    isProcessing: boolean
    error: string | null
    items: GavialTransfer[]
}

type TransfersState = {
    list: TransfersListState
    creation: TransferCreationState
}

const initialState: TransfersState = {
    list: {
        loaded: false,
        isProcessing: false,
        error: null,
        items: [],
    },
    creation: {
        isProcessing: false,
        error: null,
        created: null,
    },
}

export const createTransfer = createAsyncThunk<GavialRegisterTransferResponse, GavialRegisterTransferRequest, {
    rejectValue: string
}>(
    'createTransfer',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.transfers.serviceRegisterTransfer(request, {
                headers: {}
            })

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка создания перевода')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка создания перевода')
        }
    }
)

export const loadTransfers = createAsyncThunk<GavialTransfer[], undefined, { rejectValue: string }>(
    'loadTransfers',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.transfers.serviceListTransfers()

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки переводов')
            }

            return resp.data.items ?? []
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки переводов')
        }
    }
)

const transfersSlice = createSlice({
    name: 'transfers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createTransfer.pending, (state) => {
                state.creation.isProcessing = true
                state.creation.error = null
            })
            .addCase(createTransfer.fulfilled, (state, action) => {
                clearState(state)
                state.creation.isProcessing = false
                state.creation.created = action.payload ?? {}
            })
            .addCase(createTransfer.rejected, (state, action) => {
                state.creation.isProcessing = false
                state.creation.error = action.payload ?? null
            })
            .addCase(loadTransfers.pending, (state) => {
                state.list.isProcessing = true
                state.list.error = null
            })
            .addCase(loadTransfers.fulfilled, (state, action) => {
                state.list.isProcessing = false
                state.list.items = action.payload
                state.list.loaded = true
            })
            .addCase(loadTransfers.rejected, (state, action) => {
                state.list.isProcessing = false
                state.list.error = action.payload ?? null
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                clearState(state)
            })
    }
})

export default transfersSlice.reducer;

function clearState(state: TransfersState) {
    state.list.items = []
    state.list.loaded = false
}