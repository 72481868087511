import React, {FC, useEffect, useState} from 'react';
import {Alert, Box, Button, CircularProgress, Container, IconButton, Stack} from "@mui/material";
import SearchInput from "../common/SearchInput";
import {Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import ReceiptEditPage from "./ReceiptEditPage";
import PageWithBackLink from "../common/PageWithBackLink";
import Typography from "@mui/material/Typography";
import {useAppDispatch, useAppSelector} from "../../hook";
import {loadReceipts} from "../../store/receipts";
import {Refresh} from "@mui/icons-material";
import ReceiptItem from "./ReceiptItem";
import ReceiptPage from "./ReceiptPage";

const ReceiptsPage: FC = () => {
    const dispatch = useAppDispatch()
    const receipts = useAppSelector(state => state.receipts.list)
    const [filter, setFilter] = useState('')

    useEffect(function () {
        if (!receipts.loaded) {
            dispatch(loadReceipts())
        }
    }, [])

    const state = useLocation();
    let backLinkTo = ".."
    if (state?.state !== null && state?.state["backLinkTo"]) {
        backLinkTo = state?.state["backLinkTo"]
    }
    let backLinkText = "Назад"
    if (state?.state !== null && state?.state["backLinkText"]) {
        backLinkText = state?.state["backLinkText"]
    }

    return <Container sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
    }} disableGutters>
        <Routes>
            <Route index element={
                <>
                    <Stack padding={2} spacing={2} direction="row">
                        <SearchInput onFilterChange={(filter) => {
                            setFilter(filter)
                        }}/>
                        <Button component={Link} to="create" variant="contained" color="primary" sx={{ml: 2}}>
                            Создать
                        </Button>
                    </Stack>
                    <Box sx={{
                        width: '100%',
                        maxHeight: 'inherit',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        boxSizing: 'border-box',
                        height: 'inherit',
                    }}>
                        <Container>
                            {
                                receipts.isProcessing &&
                                <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                                    <CircularProgress/>
                                </Box>
                            }
                            {
                                receipts.error != null &&
                                <Alert severity="error" action={
                                    <IconButton
                                        aria-label="reload"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            dispatch(loadReceipts())
                                        }}
                                    >
                                        <Refresh fontSize="inherit"/>
                                    </IconButton>
                                }>
                                    {receipts.error}
                                </Alert>
                            }
                            {
                                !receipts.isProcessing && receipts.items.length > 0 &&
                                <Stack spacing={1} pb={2}>
                                    {receipts.items.map((receipt, i) => {
                                        if (
                                            filter !== '' &&
                                            !((receipt?.title ?? '').toUpperCase().includes(filter.toUpperCase())
                                                // || userName(receipt?.author ?? {}).toUpperCase().includes(filter.toUpperCase())
                                            )
                                        ) {
                                            return null
                                        }

                                        return <ReceiptItem key={i} receipt={receipt}/>
                                    })}
                                </Stack>
                            }
                        </Container>
                    </Box>
                </>
            }/>
            <Route path="create/*" element={
                <PageWithBackLink
                    pageTitle={
                        <Typography flexGrow={1} textAlign="center" variant="h6">Создание</Typography>
                    }
                >
                    <ReceiptEditPage/>
                </PageWithBackLink>
            }/>
            <Route path="/:id" element={
                <PageWithBackLink
                    backLinkText={backLinkText}
                    backLinkTo={backLinkTo}
                    pageTitle={
                        <Typography flexGrow={1} textAlign="center" variant="h6">Редактирование</Typography>
                    }
                >
                    <ReceiptEditPage/>
                </PageWithBackLink>
            }/>
            <Route path="/:id/show" element={
                <PageWithBackLink
                    backLinkText={backLinkText}
                    backLinkTo={backLinkTo}
                    pageTitle={
                        <Typography flexGrow={1} textAlign="center" variant="h6">Просмотр</Typography>
                    }
                >
                    <ReceiptPage/>
                </PageWithBackLink>
            }/>
        </Routes>
    </Container>
}

export default ReceiptsPage