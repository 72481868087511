import React, {FC, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import PageWithBackLink from "../common/PageWithBackLink";
import {useAppDispatch, useAppSelector} from "../../hook";
import {Box, Button, CircularProgress, Container, Divider, InputAdornment, Stack, TextField} from "@mui/material";
import UserAvatar from "../common/UserAvatar";
import {ContentCopy, ContentCopyTwoTone, DeleteOutlined, Group, PersonAddAlt1} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {addUserContact, loadContactDetailed, removeUserContact} from "../../store/contacts";
import {userName} from "../../utils/user";


const ContactDetailedPage: FC = () => {
    const {id} = useParams();
    const userInfo = useAppSelector(state => state.user.info)
    const dispatch = useAppDispatch();

    const contactsState = useAppSelector(state => state.contacts)
    const contactsDetailed = useAppSelector(state => state.contacts.detailed)
    const navigate = useNavigate();
    const state = useLocation();
    const [isCopied, setIsCopied] = useState(false)

    let backLinkWasSet = false
    let backLinkTo = ".."
    if (state?.state !== null && state?.state["backLinkTo"]) {
        backLinkTo = state?.state["backLinkTo"]
        backLinkWasSet = true
    }

    let backLinkText = "Назад"
    if (state?.state !== null && state?.state["backLinkText"]) {
        backLinkText = state?.state["backLinkText"]
    }

    let contact = contactsDetailed.contacts[id ?? ''] ?? {
        isLoading: false,
        contact: null,
        loadingError: null,
    }

    useEffect(() => {
        if (id && contact.contact == null) {
            dispatch(loadContactDetailed(id))
        }
    }, [contact?.contact?.inContactList])

    const isReal = contact?.contact?.user?.isReal ?? false

    return <PageWithBackLink
        backLinkText={backLinkText}
        backLinkTo={backLinkTo}
        pageTitle={
            <Box display={'flex'} flexGrow={1} alignItems='center' justifyContent='center'>
                {
                    !contact.isLoading && contact.contact !== null &&
                    <UserAvatar id={contact?.contact?.user?.id ?? ''}
                                firstName={contact?.contact?.firstName}
                                lastName={contact?.contact?.lastName}
                                username={contact?.contact?.user?.username}
                                isReal={contact?.contact?.user?.isReal}
                    />
                }
            </Box>
        }
    >
        <Container sx={{
            width: '100%',
            maxHeight: 'inherit',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            boxSizing: 'border-box',
            height: 'inherit',
        }}>
            {
                contact.isLoading &&
                <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                    <CircularProgress sx={{ml: 3}}/>
                </Box>
            }
            {
                !contact.isLoading && contact.contact !== null &&
                <Stack mt={2} spacing={2}>
                    <Button startIcon={isCopied ? <ContentCopyTwoTone/> : <ContentCopy/>}  variant="text" onClick={() => {
                        const link = `${window.location.host}/contacts/${contact?.contact?.user?.id}`
                        if (navigator.clipboard) {
                            navigator.clipboard.writeText(link)
                        } else {
                            console.log(link)
                        }
                        setIsCopied(true)
                    }}>Скопировать ссылку на профиль</Button>
                    <Stack direction='row' spacing={1}>
                        <Typography>Тип:</Typography>
                        <Typography color={isReal ? 'green' : 'gray'} height='100%' overflow='auto'
                                    sx={{whiteSpace: 'nowrap'}}>
                            {isReal ? 'пользователь' : 'черновик'}
                        </Typography>
                    </Stack>
                    {
                        contact?.contact?.firstName !== '' &&
                        <Stack direction='row' spacing={1}>
                            <Typography>Имя:</Typography>
                            <Typography height='100%' overflow='auto'
                                        sx={{whiteSpace: 'nowrap'}}>{contact?.contact?.firstName ?? ''}</Typography>
                        </Stack>
                    }
                    {
                        contact?.contact?.lastName !== '' &&
                        <Stack direction='row' spacing={1}>
                            <Typography>Фамилия:</Typography>
                            <Typography height='100%' overflow='auto'
                                        sx={{whiteSpace: 'nowrap'}}>{contact?.contact?.lastName ?? ''}</Typography>
                        </Stack>
                    }
                    {/*{*/}
                    {/*    contact?.contact?.user?.id !== contact?.contact?.user?.username &&*/}
                    {/*    <Stack direction='row' spacing={1}>*/}
                    {/*        <Typography>Никнейм:</Typography>*/}
                    {/*        <Typography height='100%' overflow='auto'*/}
                    {/*                    sx={{whiteSpace: 'nowrap'}}>{contact?.contact?.user?.username ?? ''}</Typography>*/}
                    {/*    </Stack>*/}
                    {/*}*/}
                    {
                        !contact?.contact.isMe && contact?.contact?.matchingGroups && contact?.contact?.matchingGroups.length > 0 &&
                        <Stack direction='row' spacing={1}>
                            <Typography sx={{whiteSpace: 'nowrap'}}>Общие группы:</Typography>
                            <Stack flexGrow={1} spacing={1} display='flex' sx={{
                                display: 'flex',
                                overflow: 'scroll',
                                boxSizing: 'border-box',
                            }}>
                                {contact?.contact?.matchingGroups.map(function (group) {
                                    return <Stack key={group.id} component={Button} direction='row' spacing={0.5} sx={{
                                        display: 'flex',
                                        overflow: 'scroll',
                                        boxSizing: 'border-box',
                                        p: 0,
                                        m: 0,
                                        textTransform: "none",
                                        justifyContent: "left",
                                    }} onClick={function () {
                                        let groupPageBackLink = "/contacts/" + contact?.contact?.user?.id
                                        let groupPageBackLinkText = userName(contact?.contact ?? {})

                                        if (backLinkWasSet) {
                                            groupPageBackLink = backLinkTo
                                            groupPageBackLinkText = backLinkText
                                        }

                                        navigate('/groups/' + group.id, {
                                            state: {
                                                "backLinkTo": groupPageBackLink,
                                                "backLinkText": groupPageBackLinkText
                                            }
                                        })
                                    }}>
                                        <Group/>
                                        <Typography height='100%' overflow='auto' sx={{whiteSpace: 'nowrap'}}>
                                            {group.title}
                                        </Typography>
                                    </Stack>
                                })}
                            </Stack>
                        </Stack>
                    }

                </Stack>
            }
        </Container>
        {
            !contact?.contact?.isMe && contact?.contact &&
            <>
                <Divider/>
                <Stack ml={2} mr={2} mt={2}>
                    {
                        contact?.contact?.inContactList &&
                        <LoadingButton loading={contactsState.removal.isProcessing}
                                       loadingPosition='start'
                                       color='error'
                                       variant={'contained'}
                                       startIcon={<DeleteOutlined/>}
                                       onClick={() => {
                                           dispatch(removeUserContact(contact?.contact?.user?.id ?? ''))
                                       }}>
                            Удалить из списка контактов
                        </LoadingButton>
                    }
                    {
                        !contact?.contact?.inContactList &&
                        <LoadingButton sx={{position: "sticky"}}
                                       loading={contactsState.addition.isProcessing}
                                       loadingPosition='start'
                                       color='success'
                                       variant="contained" startIcon={<PersonAddAlt1/>}
                                       onClick={() => {
                                           dispatch(addUserContact(contact?.contact?.user?.id ?? ''))
                                       }}>
                            Добавить в список контактов
                        </LoadingButton>
                    }
                </Stack>
            </>
        }
    </PageWithBackLink>
}

export default ContactDetailedPage