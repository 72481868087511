import React, {FC} from "react";
import Typography from "@mui/material/Typography";
import {
    Autocomplete, Box,
    Button,
    IconButton, MenuItem,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {ConsumptionsType, DiscountsType, GavialPositionInput, GavialUserContact} from "../../api/Api";
import {AddCircleOutline, DeleteOutline, RemoveCircleOutline} from "@mui/icons-material";
import {NumericFormat} from "react-number-format";
import {userName} from "../../utils/user";
import {useAppSelector} from "../../hook";
import {v4 as uuid} from "uuid";

interface PositionEditProps {
    positionRequest: GavialPositionInput
    onChange: (position: GavialPositionInput) => void
    onRemove: (position: GavialPositionInput) => void
}

const PositionEditPanel: FC<PositionEditProps> = ({positionRequest, onChange, onRemove}) => {
    const contacts = useAppSelector(state => state.contacts.list)

    function onConsumptionTypeChange(value: ConsumptionsType) {
        positionRequest.consumptionType = value
        positionRequest.consumptions?.forEach((consumption) => {
            consumption.type = value
            consumption.paramsPieces = undefined
            consumption.paramsExactAmount = undefined
            consumption.paramsEquality = undefined
            consumption.paramsAdjustment = undefined
        })
        onChange(positionRequest)
    }

    return <Stack spacing={2} alignItems="top" direction="row">
        <Typography sx={{mt: 2}}>{positionRequest.index ?? 0}</Typography>
        <Stack spacing={1} flexGrow={1}>
            <Stack flexGrow={1} direction="row" alignItems="center" display="flex" spacing={1}>
                <TextField fullWidth sx={{flexGrow: 1}} autoComplete="off" variant="outlined"
                           value={positionRequest.title}
                           label="Название позиции"
                           onChange={(event) => {
                               positionRequest.title = event.target.value
                               onChange(positionRequest)
                           }}
                />
                <NumericFormat
                    customInput={TextField}
                    inputMode={'decimal'}
                    label='Цена'
                    autoComplete='off'
                    thousandSeparator=' '
                    allowedDecimalSeparators={['.', ',']}
                    decimalScale={2}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    value={positionRequest.amount ? +positionRequest?.amount / 100 : null}
                    onChange={function (event) {
                        const cents = Math.round(parseFloat(event.target.value.replace(/\s/g, "")) * 100)
                        positionRequest.amount = cents + ''
                        onChange(positionRequest)
                    }}
                />
                <IconButton sx={{m: 0, p: 0}}
                            color='primary'
                            onClick={() => {
                                onRemove(positionRequest)
                            }}
                >
                    <DeleteOutline/>
                </IconButton>
            </Stack>
            <Stack direction="row" alignItems="center" display="flex" spacing={1}>
                <Typography noWrap>Как делить</Typography>
                <Select
                    sx={{minWidth: 100, flexGrow: 1, mr:5}}
                    value={positionRequest.consumptionType}
                    onChange={(e) => {
                        onConsumptionTypeChange(e.target.value as ConsumptionsType)
                    }}
                >
                    <MenuItem value={ConsumptionsType.TYPE_EQUALITY}>Поровну</MenuItem>
                    <MenuItem value={ConsumptionsType.TYPE_PIECES}>По долям</MenuItem>
                    <MenuItem value={ConsumptionsType.TYPE_EXACT_AMOUNT}>Указать сумму</MenuItem>
                </Select>
                <Box minWidth={24}></Box>
            </Stack>
            {
                positionRequest.consumptions?.map((consumption, index) => {
                    let user: GavialUserContact|null = null
                    if (consumption.userId) {
                        user = contacts.contactByID[consumption.userId]
                    }

                    return <Stack key={index} alignItems="center" direction="row" spacing={1}>
                        <Autocomplete
                            sx={{flexGrow: 1}}
                            autoComplete={false}
                            options={contacts.contacts}
                            noOptionsText={'нет подходящих вариантов'}
                            getOptionLabel={(option) => userName(option)}
                            renderInput={(params) => <TextField label="Участник позиции" {...params}/>}
                            value={user}
                            onChange={function (event, value) {
                                consumption.userId = value?.userId
                                onChange(positionRequest)
                            }}
                        />
                        {
                            consumption.type === ConsumptionsType.TYPE_PIECES &&
                            <NumericFormat
                                customInput={TextField}
                                inputMode={'decimal'}
                                sx={{maxWidth: 70}}
                                label='Доля'
                                autoComplete='off'
                                thousandSeparator=' '
                                allowedDecimalSeparators={['.', ',']}
                                decimalScale={2}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                value={consumption.paramsPieces?.pieces}
                                onChange={function (event) {
                                    consumption.paramsPieces = {pieces: +event.target.value}
                                    onChange(positionRequest)
                                }}
                            />
                        }
                        {
                            positionRequest.consumptionType === ConsumptionsType.TYPE_EXACT_AMOUNT &&
                            <NumericFormat
                                customInput={TextField}
                                inputMode={'decimal'}
                                sx={{maxWidth: 80}}
                                label='Сумма'
                                autoComplete='off'
                                thousandSeparator=' '
                                allowedDecimalSeparators={['.', ',']}
                                decimalScale={2}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                value={consumption.paramsExactAmount?.amount ? +consumption.paramsExactAmount?.amount / 100 : null}
                                onChange={function (event) {
                                    const cents = Math.round(parseFloat(event.target.value.replace(/\s/g, "")) * 100)
                                    consumption.paramsExactAmount = {amount: cents + ''}
                                    onChange(positionRequest)
                                }}
                            />
                        }
                        <IconButton sx={{ml: 0, p: 0}} size={'small'} color='primary' onClick={() => {
                            positionRequest.consumptions?.splice(index, 1)
                            onChange(positionRequest)
                        }}
                        >
                            <RemoveCircleOutline/>
                        </IconButton>
                    </Stack>
                })
            }
            <Button size={'large'} startIcon={<AddCircleOutline/>}
                    sx={{
                        textTransform: "none",
                        // alignItems: "left"
                    }}
                    onClick={() => {
                        positionRequest.consumptions?.push({
                            id: uuid(),
                            index: positionRequest.consumptions?.length + 1,
                            // userId: string;
                            type: positionRequest.consumptionType,
                            // paramsEquality? : GavialConsumptionParamsEqualityInput;
                            // paramsExactAmount? : GavialConsumptionParamsExactAmountInput;
                            // paramsPieces? : GavialConsumptionParamsPiecesInput;
                            // paramsAdjustment? : GavialConsumptionParamsAdjustmentInput;
                        })
                        onChange(positionRequest)
                    }}
            >
                Добавить
            </Button>
            {/*</Box>*/}
        </Stack>
    </Stack>
}

export default PositionEditPanel