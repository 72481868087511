/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GroupDetailedMyInfo {
  IsOwner?: boolean;
  IsMember?: boolean;
  CanJoin?: boolean;
}

/** @default "TYPE_UNSPECIFIED" */
export enum ConsumptionsType {
  TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
  TYPE_EQUALITY = "TYPE_EQUALITY",
  TYPE_EXACT_AMOUNT = "TYPE_EXACT_AMOUNT",
  TYPE_PIECES = "TYPE_PIECES",
  TYPE_ADJUSTMENT = "TYPE_ADJUSTMENT",
}

/** @default "TYPE_UNSPECIFIED" */
export enum DiscountsType {
  TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
  TYPE_PERCENTAGES = "TYPE_PERCENTAGES",
  TYPE_EXACT_AMOUNT = "TYPE_EXACT_AMOUNT",
}

export interface GavialAddContactResponse {
  contact?: GavialUserContact;
}

export interface GavialAuthByTelegramRequest {
  authData?: Record<string, string>;
  source?: GavialTelegramAuthSource;
}

export interface GavialAuthByTelegramResponse {
  token?: string;
}

export interface GavialChangeMeRequest {
  firstName?: string;
  lastName?: string;
  currency?: string;
}

export type GavialChangeMeResponse = object;

export interface GavialConsumption {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  index?: number;
  user?: GavialUserContact;
  type?: ConsumptionsType;
  paramsEquality?: GavialConsumptionParamsEquality;
  paramsExactAmount?: GavialConsumptionParamsExactAmount;
  paramsPieces?: GavialConsumptionParamsPieces;
  paramsAdjustment?: GavialConsumptionParamsAdjustment;
  calculatedAmount?: GavialMoney;
}

export interface GavialConsumptionInput {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  index?: number;
  /** @format uuid */
  userId?: string;
  type?: ConsumptionsType;
  paramsEquality?: GavialConsumptionParamsEqualityInput;
  paramsExactAmount?: GavialConsumptionParamsExactAmountInput;
  paramsPieces?: GavialConsumptionParamsPiecesInput;
  paramsAdjustment?: GavialConsumptionParamsAdjustmentInput;
}

export interface GavialConsumptionParamsAdjustment {
  adjustmentAmount?: GavialMoney;
}

export interface GavialConsumptionParamsAdjustmentInput {
  /**
   * @format int64
   * @example 10050
   */
  adjustmentAmount?: string;
}

export type GavialConsumptionParamsEquality = object;

export type GavialConsumptionParamsEqualityInput = object;

export interface GavialConsumptionParamsExactAmount {
  amount?: GavialMoney;
}

export interface GavialConsumptionParamsExactAmountInput {
  /**
   * @format int64
   * @example 10050
   */
  amount?: string;
}

export interface GavialConsumptionParamsPieces {
  /** @format double */
  pieces?: number;
}

export interface GavialConsumptionParamsPiecesInput {
  /** @format double */
  pieces?: number;
}

export interface GavialCreateDraftUserContactRequest {
  /** @format uuid */
  id?: string;
  firstName?: string;
  lastName?: string;
}

export interface GavialCreateDraftUserContactResponse {
  user?: GavialUserContact;
}

export interface GavialCreateGroupRequest {
  /** @format uuid */
  id?: string;
  title?: string;
}

export interface GavialCreateGroupResponse {
  group?: GavialGroup;
}

export interface GavialCurrencyBalance {
  /**
   * @format currency
   * @example "EUR"
   */
  currency?: string;
  userBalances?: GavialUserBalance[];
  sum?: GavialMoney;
}

export interface GavialDebt {
  /** @format int32 */
  index?: number;
  creditor?: GavialUserContact;
  debtor?: GavialUserContact;
  amount?: GavialMoney;
}

export interface GavialDiscount {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  index?: number;
  allPositions?: boolean;
  positions?: string[];
  type?: DiscountsType;
  paramsPercentages?: GavialDiscountParamsPercentages;
  paramsExactAmount?: GavialDiscountParamsExactAmount;
}

export interface GavialDiscountInput {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  index?: number;
  allPositions?: boolean;
  /** @format uuid */
  positions?: string[];
  type?: DiscountsType;
  paramsPercentages?: GavialDiscountParamsPercentagesInput;
  paramsExactAmount?: GavialDiscountParamsExactAmountInput;
}

export interface GavialDiscountParamsExactAmount {
  amount?: GavialMoney;
}

export interface GavialDiscountParamsExactAmountInput {
  /**
   * @format int64
   * @example 10050
   */
  amount?: string;
}

export interface GavialDiscountParamsPercentages {
  /** @format double */
  percentages?: number;
}

export interface GavialDiscountParamsPercentagesInput {
  /** @format double */
  percentages?: number;
}

export interface GavialGetContactDetailedResponse {
  contact?: GavialUserContactDetailed;
}

export interface GavialGetGroupDetailedResponse {
  group?: GavialGroupDetailed;
}

export interface GavialGetMeResponse {
  user?: GavialUser;
  currency?: string;
}

export interface GavialGetReceiptResponse {
  receipt?: GavialReceipt;
}

export interface GavialGroup {
  /** @format uuid */
  id?: string;
  title?: string;
}

export interface GavialGroupDetailed {
  group?: GavialGroup;
  members?: GavialGroupMember[];
  owner?: GavialUserContact;
  myInfo?: GroupDetailedMyInfo;
}

export interface GavialGroupMember {
  contact?: GavialUserContact;
}

export type GavialJoinGroupResponse = object;

export type GavialLeaveGroupResponse = object;

export interface GavialListBalancesResponse {
  items?: GavialCurrencyBalance[];
}

export interface GavialListContactsResponse {
  items?: GavialUserContact[];
}

export interface GavialListCurrencyResponse {
  items?: string[];
}

export interface GavialListGroupsResponse {
  items?: GavialGroup[];
}

export interface GavialListMembersResponse {
  items?: GavialGroupMember[];
}

export interface GavialListNewsResponse {
  items?: GavialNews[];
}

export interface GavialListReceiptsResponse {
  items?: GavialReceiptItem[];
}

export interface GavialListTransfersResponse {
  items?: GavialTransfer[];
}

export interface GavialMoney {
  /**
   * @format int64
   * @example 10050
   */
  cents?: string;
  /**
   * @format decimal
   * @example "100.50"
   */
  amount?: string;
  /**
   * @format currency
   * @example "EUR"
   */
  currency?: string;
}

export interface GavialNews {
  /** @format uuid */
  id?: string;
  type?: NewsType;
  /** @format date-time */
  createdAt?: string;
  transferRegistered?: GavialNewsParamsTransferRegistered;
  receiptProcessed?: GavialNewsParamsReceiptProcessed;
  receiptRollback?: GavialNewsParamsReceiptRollback;
}

export interface GavialNewsParamsReceiptProcessed {
  receipt?: GavialReceiptItem;
  balanceChange?: GavialMoney;
}

export interface GavialNewsParamsReceiptRollback {
  receipt?: GavialReceiptItem;
  balanceChange?: GavialMoney;
}

export interface GavialNewsParamsTransferRegistered {
  transfer?: GavialTransfer;
  balanceChange?: GavialMoney;
}

export interface GavialPayment {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  index?: number;
  user?: GavialUserContact;
  amount?: GavialMoney;
}

export interface GavialPaymentInput {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  index?: number;
  /** @format uuid */
  userId?: string;
  /**
   * @format int64
   * @example 10050
   */
  amount?: string;
}

export interface GavialPosition {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  index?: number;
  title?: string;
  amount?: GavialMoney;
  discountedAmount?: GavialMoney;
  consumptionType?: ConsumptionsType;
  consumptions?: GavialConsumption[];
}

export interface GavialPositionInput {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  index?: number;
  title?: string;
  /**
   * @format int64
   * @example 10050
   */
  amount?: string;
  consumptionType?: ConsumptionsType;
  consumptions?: GavialConsumptionInput[];
}

export interface GavialPrecalculateReceiptRequest {
  receipt?: GavialReceiptInput;
}

export interface GavialPrecalculateReceiptResponse {
  receipt?: GavialReceipt;
}

export interface GavialProcessReceiptRequest {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  version?: number;
}

export interface GavialProcessReceiptResponse {
  receipt?: GavialReceipt;
}

export interface GavialReceipt {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  version?: number;
  author?: GavialUserContact;
  title?: string;
  status?: ReceiptsStatus;
  /**
   * @format currency
   * @example "EUR"
   */
  currency?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  processedAt?: string;
  positions?: GavialPosition[];
  discounts?: GavialDiscount[];
  payments?: GavialPayment[];
  debts?: GavialDebt[];
  hints?: string[];
}

export interface GavialReceiptInput {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  version?: number;
  title?: string;
  /**
   * @format currency
   * @example "EUR"
   */
  currency?: string;
  positions?: GavialPositionInput[];
  discounts?: GavialDiscountInput[];
  payments?: GavialPaymentInput[];
}

export interface GavialReceiptItem {
  /** @format uuid */
  id?: string;
  author?: GavialUserContact;
  title?: string;
  status?: ReceiptsStatus;
  /**
   * @format currency
   * @example "EUR"
   */
  currency?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  processedAt?: string;
}

export interface GavialRegisterTransferRequest {
  /** @format uuid */
  id?: string;
  type?: TransfersType;
  /** @format uuid */
  participantId?: string;
  /**
   * @format currency
   * @example "EUR"
   */
  currency?: string;
  /** @format int64 */
  amount?: string;
  description?: string;
}

export type GavialRegisterTransferResponse = object;

export type GavialRemindAboutDebtResponse = object;

export type GavialRemoveContactResponse = object;

export interface GavialRollbackReceiptRequest {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  version?: number;
}

export interface GavialRollbackReceiptResponse {
  receipt?: GavialReceipt;
}

export interface GavialSaveReceiptRequest {
  receipt?: GavialReceiptInput;
}

export interface GavialSaveReceiptResponse {
  receipt?: GavialReceipt;
}

/** @default "TELEGRAM_AUTH_SOURCE_UNSPECIFIED" */
export enum GavialTelegramAuthSource {
  TELEGRAM_AUTH_SOURCE_UNSPECIFIED = "TELEGRAM_AUTH_SOURCE_UNSPECIFIED",
  TELEGRAM_AUTH_SOURCE_WIDGET = "TELEGRAM_AUTH_SOURCE_WIDGET",
  TELEGRAM_AUTH_SOURCE_WEBAPP = "TELEGRAM_AUTH_SOURCE_WEBAPP",
}

export interface GavialTransfer {
  id?: string;
  author?: GavialUserContact;
  sender?: GavialUserContact;
  recipient?: GavialUserContact;
  amount?: GavialMoney;
  description?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
}

export interface GavialUser {
  /** @format uuid */
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  isReal?: boolean;
}

export interface GavialUserBalance {
  user?: GavialUserContact;
  amount?: GavialMoney;
  remindAvailable?: boolean;
}

export interface GavialUserContact {
  /** @format uuid */
  userId?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  isReal?: boolean;
  inContactList?: boolean;
  isMe?: boolean;
}

export interface GavialUserContactDetailed {
  user?: GavialUser;
  firstName?: string;
  lastName?: string;
  inContactList?: boolean;
  matchingGroups?: GavialGroup[];
  isMe?: boolean;
}

export interface GooglerpcStatus {
  /** @format int32 */
  code?: number;
  message?: string;
  details?: ProtobufAny[];
}

/** @default "TYPE_UNSPECIFIED" */
export enum NewsType {
  TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
  TYPE_TRANSFER_REGISTERED = "TYPE_TRANSFER_REGISTERED",
  TYPE_RECEIPT_PROCESSED = "TYPE_RECEIPT_PROCESSED",
  TYPE_RECEIPT_ROLLBACK = "TYPE_RECEIPT_ROLLBACK",
}

export interface ProtobufAny {
  "@type"?: string;
  [key: string]: any;
}

/** @default "STATUS_UNSPECIFIED" */
export enum ReceiptsStatus {
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  STATUS_CREATED = "STATUS_CREATED",
  STATUS_READY_TO_PROCESS = "STATUS_READY_TO_PROCESS",
  STATUS_PROCESSING = "STATUS_PROCESSING",
  STATUS_PROCESSED = "STATUS_PROCESSED",
}

/** @default "TYPE_UNSPECIFIED" */
export enum TransfersType {
  TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
  TYPE_OUTGOING = "TYPE_OUTGOING",
  TYPE_INCOMING = "TYPE_INCOMING",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "//localhost:8080";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title gavial
 * @version 0.0.1
 * @baseUrl //localhost:8080
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name ServiceAuthByTelegram
     * @request POST:/auth/telegram
     */
    serviceAuthByTelegram: (body: GavialAuthByTelegramRequest, params: RequestParams = {}) =>
      this.request<GavialAuthByTelegramResponse, GooglerpcStatus>({
        path: `/auth/telegram`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  balances = {
    /**
     * No description
     *
     * @tags Balance
     * @name ServiceListBalances
     * @request GET:/balances
     * @secure
     */
    serviceListBalances: (params: RequestParams = {}) =>
      this.request<GavialListBalancesResponse, GooglerpcStatus>({
        path: `/balances`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  contacts = {
    /**
     * No description
     *
     * @tags Contacts
     * @name ServiceListContacts
     * @request GET:/contacts
     * @secure
     */
    serviceListContacts: (params: RequestParams = {}) =>
      this.request<GavialListContactsResponse, GooglerpcStatus>({
        path: `/contacts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contacts
     * @name ServiceAddContact
     * @request POST:/contacts/add/{id}
     * @secure
     */
    serviceAddContact: (id: string, params: RequestParams = {}) =>
      this.request<GavialAddContactResponse, GooglerpcStatus>({
        path: `/contacts/add/${id}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contacts
     * @name ServiceCreateDraftUserContact
     * @request POST:/contacts/draft
     * @secure
     */
    serviceCreateDraftUserContact: (body: GavialCreateDraftUserContactRequest, params: RequestParams = {}) =>
      this.request<GavialCreateDraftUserContactResponse, GooglerpcStatus>({
        path: `/contacts/draft`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contacts
     * @name ServiceGetContactDetailed
     * @request GET:/contacts/{id}
     * @secure
     */
    serviceGetContactDetailed: (id: string, params: RequestParams = {}) =>
      this.request<GavialGetContactDetailedResponse, GooglerpcStatus>({
        path: `/contacts/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contacts
     * @name ServiceRemoveContact
     * @request DELETE:/contacts/{id}
     * @secure
     */
    serviceRemoveContact: (id: string, params: RequestParams = {}) =>
      this.request<GavialRemoveContactResponse, GooglerpcStatus>({
        path: `/contacts/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  dict = {
    /**
     * No description
     *
     * @tags Dict
     * @name ServiceListCurrency
     * @request GET:/dict/currency
     * @secure
     */
    serviceListCurrency: (params: RequestParams = {}) =>
      this.request<GavialListCurrencyResponse, GooglerpcStatus>({
        path: `/dict/currency`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  groups = {
    /**
     * No description
     *
     * @tags Groups
     * @name ServiceListGroups
     * @request GET:/groups
     * @secure
     */
    serviceListGroups: (params: RequestParams = {}) =>
      this.request<GavialListGroupsResponse, GooglerpcStatus>({
        path: `/groups`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name ServiceCreateGroup
     * @request POST:/groups
     * @secure
     */
    serviceCreateGroup: (body: GavialCreateGroupRequest, params: RequestParams = {}) =>
      this.request<GavialCreateGroupResponse, GooglerpcStatus>({
        path: `/groups`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name ServiceJoinGroup
     * @request POST:/groups/{groupId}/join
     * @secure
     */
    serviceJoinGroup: (groupId: string, params: RequestParams = {}) =>
      this.request<GavialJoinGroupResponse, GooglerpcStatus>({
        path: `/groups/${groupId}/join`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name ServiceLeaveGroup
     * @request POST:/groups/{groupId}/leave
     * @secure
     */
    serviceLeaveGroup: (groupId: string, params: RequestParams = {}) =>
      this.request<GavialLeaveGroupResponse, GooglerpcStatus>({
        path: `/groups/${groupId}/leave`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name ServiceGetGroupDetailed
     * @request GET:/groups/{id}
     * @secure
     */
    serviceGetGroupDetailed: (id: string, params: RequestParams = {}) =>
      this.request<GavialGetGroupDetailedResponse, GooglerpcStatus>({
        path: `/groups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name ServiceListMembers
     * @request GET:/groups/{id}/members
     * @secure
     */
    serviceListMembers: (id: string, params: RequestParams = {}) =>
      this.request<GavialListMembersResponse, GooglerpcStatus>({
        path: `/groups/${id}/members`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  news = {
    /**
     * No description
     *
     * @tags News
     * @name ServiceListNews
     * @request GET:/news
     * @secure
     */
    serviceListNews: (params: RequestParams = {}) =>
      this.request<GavialListNewsResponse, GooglerpcStatus>({
        path: `/news`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  receipts = {
    /**
     * No description
     *
     * @tags Receipts
     * @name ServiceListReceipts
     * @request GET:/receipts
     * @secure
     */
    serviceListReceipts: (params: RequestParams = {}) =>
      this.request<GavialListReceiptsResponse, GooglerpcStatus>({
        path: `/receipts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipts
     * @name ServiceSaveReceipt
     * @summary Создать новый чек или обновить существующий
     * @request POST:/receipts
     * @secure
     */
    serviceSaveReceipt: (body: GavialSaveReceiptRequest, params: RequestParams = {}) =>
      this.request<GavialSaveReceiptResponse, GooglerpcStatus>({
        path: `/receipts`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipts
     * @name ServicePrecalculateReceipt
     * @request POST:/receipts/precalculate
     * @secure
     */
    servicePrecalculateReceipt: (body: GavialPrecalculateReceiptRequest, params: RequestParams = {}) =>
      this.request<GavialPrecalculateReceiptResponse, GooglerpcStatus>({
        path: `/receipts/precalculate`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipts
     * @name ServiceProcessReceipt
     * @request POST:/receipts/process
     * @secure
     */
    serviceProcessReceipt: (body: GavialProcessReceiptRequest, params: RequestParams = {}) =>
      this.request<GavialProcessReceiptResponse, GooglerpcStatus>({
        path: `/receipts/process`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipts
     * @name ServiceRollbackReceipt
     * @request POST:/receipts/rollback
     * @secure
     */
    serviceRollbackReceipt: (body: GavialRollbackReceiptRequest, params: RequestParams = {}) =>
      this.request<GavialRollbackReceiptResponse, GooglerpcStatus>({
        path: `/receipts/rollback`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipts
     * @name ServiceGetReceipt
     * @request GET:/receipts/{id}
     * @secure
     */
    serviceGetReceipt: (id: string, params: RequestParams = {}) =>
      this.request<GavialGetReceiptResponse, GooglerpcStatus>({
        path: `/receipts/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  remind = {
    /**
     * No description
     *
     * @tags Notification
     * @name ServiceRemindAboutDebt
     * @request POST:/remind/debt
     * @secure
     */
    serviceRemindAboutDebt: (
      query?: {
        /** @format uuid */
        recipientId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GavialRemindAboutDebtResponse, GooglerpcStatus>({
        path: `/remind/debt`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  transfers = {
    /**
     * No description
     *
     * @tags Transfers
     * @name ServiceListTransfers
     * @request GET:/transfers
     * @secure
     */
    serviceListTransfers: (params: RequestParams = {}) =>
      this.request<GavialListTransfersResponse, GooglerpcStatus>({
        path: `/transfers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transfers
     * @name ServiceRegisterTransfer
     * @request POST:/transfers
     * @secure
     */
    serviceRegisterTransfer: (body: GavialRegisterTransferRequest, params: RequestParams = {}) =>
      this.request<GavialRegisterTransferResponse, GooglerpcStatus>({
        path: `/transfers`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags User
     * @name ServiceGetMe
     * @request GET:/users/me
     * @secure
     */
    serviceGetMe: (params: RequestParams = {}) =>
      this.request<GavialGetMeResponse, GooglerpcStatus>({
        path: `/users/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ServiceChangeMe
     * @request POST:/users/me
     * @secure
     */
    serviceChangeMe: (body: GavialChangeMeRequest, params: RequestParams = {}) =>
      this.request<GavialChangeMeResponse, GooglerpcStatus>({
        path: `/users/me`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
