import {GavialCurrencyBalance} from "../api/Api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "../apiClient/api";
import {loadContacts} from "./contacts";
import {createTransfer} from "./transfers";
import {processReceipt, rollbackReceipt} from "./receipts";

interface BalanceListState {
    loaded: boolean
    isProcessing: boolean
    error: string | null
    items: GavialCurrencyBalance[]
}

interface DebtRemindState {
    isProcessing: boolean
    error: string | null
}

type BalancesState = {
    list: BalanceListState
    debtRemind: DebtRemindState
}

const initialState: BalancesState = {
    list: {
        loaded: false,
        isProcessing: false,
        error: null,
        items: [],
    },
    debtRemind: {
        isProcessing: false,
        error: null,
    }
}

export const loadBalances = createAsyncThunk<GavialCurrencyBalance[], undefined, { rejectValue: string }>(
    'loadBalances',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.balances.serviceListBalances()

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки баланса')
            }

            return resp.data.items ?? []
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки баланса')
        }
    }
)

export const remindAboutDebt = createAsyncThunk<undefined, string, { rejectValue: string }>(
    'remindAboutDebt',
    async function (recipientId, {rejectWithValue}) {
        try {
            const resp = await api.remind.serviceRemindAboutDebt({recipientId})

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка')
            }

            return
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка')
        }
    }
)

const balanceSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadBalances.pending, (state) => {
                state.list.isProcessing = true
                state.list.error = null
            })
            .addCase(loadBalances.fulfilled, (state, action) => {
                state.list.isProcessing = false
                state.list.items = action.payload
                state.list.loaded = true
            })
            .addCase(loadContacts.rejected, (state, action) => {
                state.list.isProcessing = false
                state.list.error = action.payload ?? null
            })
            .addCase(remindAboutDebt.fulfilled, (state, action) => {
                clearState(state)
            })
            .addCase(createTransfer.fulfilled, (state, action) => {
                clearState(state)
            })
            .addCase(processReceipt.fulfilled, (state, action) => {
                clearState(state)
            })
            .addCase(rollbackReceipt.fulfilled, (state, action) => {
                clearState(state)
            })
    }
})

function clearState(state: BalancesState) {
    state.list.items = []
    state.list.loaded = false
}

export default balanceSlice.reducer;