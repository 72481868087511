import {Api, RequestParams} from "../api/Api";

interface ApiSecurityData {
    token: string
    exp: number
}

const api = new Api<ApiSecurityData>({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    securityWorker: (securityData: ApiSecurityData | null): RequestParams | void => {
        if (securityData === null) {
            return
        }

        if (Date.now() / 1000 > (securityData.exp ?? 0)) {
            // store.dispatch({type: AuthActionTypes.AUTH_LOGOUT})
            return
        }

        return {
            headers: {
                'authorization': securityData?.token ?? ''
            }
        }
    }
});

export default api