import React, {FC, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import PageWithBackLink from "../common/PageWithBackLink";
import {useAppDispatch, useAppSelector} from "../../hook";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
    TextField
} from "@mui/material";
import {
    AccountBox,
    ContentCopy,
    ContentCopyTwoTone,
    DeleteOutlined,
    Group,
    Login,
    Logout,
    PersonAddAlt1
} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {joinGroup, leaveGroup, loadGroupDetailed} from "../../store/groups";
import {stringToColor} from "../../utils/color";
import {userName} from "../../utils/user";
import LoadingButton from "@mui/lab/LoadingButton";
import {addUserContact, loadContactDetailed, removeUserContact} from "../../store/contacts";


const GroupDetailedPage: FC = () => {
    const {id} = useParams();
    const dispatch = useAppDispatch();

    const groupsState = useAppSelector(state => state.groups)
    const groupsDetailed = useAppSelector(state => state.groups.detailed)
    const navigate = useNavigate();
    const state = useLocation();
    const [isCopied, setIsCopied] = useState(false)

    let backLinkWasSet = false
    let backLinkTo = ".."
    if (state?.state !== null && state?.state["backLinkTo"]) {
        backLinkTo = state?.state["backLinkTo"]
        backLinkWasSet = true
    }

    let backLinkText = "Назад"
    if (state?.state !== null && state?.state["backLinkText"]) {
        backLinkText = state?.state["backLinkText"]
    }

    let group = groupsDetailed.items[id ?? ''] ?? {
        isLoading: false,
        contact: null,
        loadingError: null,
    }

    useEffect(() => {
        if (id && group.item == null) {
            dispatch(loadGroupDetailed(id))
        }
    }, [group.item?.myInfo?.IsMember])

    return <PageWithBackLink
        backLinkText={backLinkText}
        backLinkTo={backLinkTo}
        pageTitle={
            <Box display={'flex'} flexGrow={1} alignItems='center' justifyContent='center'>
                {
                    !group.isLoading && group.item !== null &&
                    <Avatar
                        sx={{width: 40, height: 40, bgcolor: stringToColor(group?.item?.group?.id ?? '')}}>
                        <Group/>
                    </Avatar>
                }
            </Box>
        }
    >
        <Container sx={{
            width: '100%',
            maxHeight: 'inherit',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            boxSizing: 'border-box',
            height: 'inherit',
        }}>
            {
                group.isLoading &&
                <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                    <CircularProgress sx={{ml: 3}}/>
                </Box>
            }
            {
                !group.isLoading && group.item !== null &&
                <Stack mt={2} spacing={2}>
                    <Button startIcon={isCopied ? <ContentCopyTwoTone/> : <ContentCopy/>}  variant="text" onClick={() => {
                        const link = `${window.location.host}/groups/${group?.item?.group?.id}`
                        if (navigator.clipboard) {
                            navigator.clipboard.writeText(link)
                        } else {
                            console.log(link)
                        }
                        setIsCopied(true)
                    }}>Скопировать ссылку на группу</Button>
                    {
                        group?.item?.group?.title &&
                        <Stack direction='row' spacing={1}>
                            <Typography>Название:</Typography>
                            <Typography height='100%' overflow='auto'
                                        sx={{whiteSpace: 'nowrap'}}>{group?.item?.group?.title ?? ''}</Typography>
                        </Stack>
                    }
                    {
                        group?.item?.members &&
                        <Stack direction='row' spacing={1}>
                            <Typography sx={{whiteSpace: 'nowrap'}}>Участники:</Typography>
                            <Stack flexGrow={1} spacing={1} display='flex' sx={{
                                display: 'flex',
                                overflow: 'scroll',
                                boxSizing: 'border-box',
                            }}>
                                {group?.item?.members.map(function (member) {
                                    return <Stack key={member?.contact?.userId} component={Button} direction='row'
                                                  spacing={0.5} sx={{
                                        display: 'flex',
                                        overflow: 'scroll',
                                        boxSizing: 'border-box',
                                        p: 0,
                                        m: 0,
                                        textTransform: "none",
                                        justifyContent: "left",
                                    }} onClick={function () {
                                        let contactPageBackLink = "/groups/" + group?.item?.group?.id
                                        let contactPageBackLinkText = group?.item?.group?.title

                                        if (backLinkWasSet) {
                                            contactPageBackLink = backLinkTo
                                            contactPageBackLinkText = backLinkText
                                        }

                                        navigate('/contacts/' + member?.contact?.userId, {
                                            state: {
                                                "backLinkTo": contactPageBackLink,
                                                "backLinkText": contactPageBackLinkText,
                                            },
                                        })
                                    }}>
                                        <AccountBox/>
                                        <Typography height='100%' overflow='auto' sx={{whiteSpace: 'nowrap'}}>
                                            {userName(member?.contact ?? {})}
                                        </Typography>
                                    </Stack>
                                })}
                            </Stack>
                        </Stack>
                    }
                </Stack>
            }
        </Container>
        {
            (group.item?.myInfo?.CanJoin || group.item?.myInfo?.IsMember) &&
            <>
                <Divider/>
                <Stack ml={2} mr={2} mt={2}>
                    {
                        group.item?.myInfo?.IsMember &&
                        <LoadingButton loading={groupsState.leave.isProcessing}
                                       loadingPosition='start'
                                       color='error'
                                       variant={'contained'}
                                       startIcon={<Logout/>}
                                       onClick={() => {
                                           dispatch(leaveGroup(group.item?.group?.id ?? ''))
                                       }}>
                            Покинуть группу
                        </LoadingButton>
                    }
                    {
                        group.item?.myInfo?.CanJoin &&
                        <LoadingButton sx={{position: "sticky"}}
                                       loading={groupsState.join.isProcessing}
                                       loadingPosition='start'
                                       color='success'
                                       variant="contained"
                                       startIcon={<Login/>}
                                       onClick={() => {
                                           dispatch(joinGroup(group.item?.group?.id ?? ''))
                                       }}>
                            Вступить в группу
                        </LoadingButton>
                    }
                </Stack>
            </>
        }
    </PageWithBackLink>
}

export default GroupDetailedPage