import React, {FC, useEffect, useState} from "react";
import {Alert, Autocomplete, Box, Container, IconButton, Stack, TextField} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hook";
import {userName} from "../../utils/user";
import {GavialRegisterTransferRequest, GavialUserBalance, GavialUserContact, TransfersType} from "../../api/Api";
import {v4 as uuid} from "uuid";
import {Send, SwapVert} from "@mui/icons-material";
import {createTransfer} from "../../store/transfers";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {NumericFormat} from 'react-number-format';
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import PageWithBackLink from "../common/PageWithBackLink";


const TransferCreatePage: FC = () => {
    const userInfo = useAppSelector(state => state.user.info)
    const contacts = useAppSelector(state => state.contacts.list)
    const transferCreation = useAppSelector(state => state.transfers.creation)
    const dicts = useAppSelector(state => state.dicts)
    const dispatch = useAppDispatch()

    const [request, setRequest] = useState<GavialRegisterTransferRequest>({
        id: uuid(),
        type: TransfersType.TYPE_OUTGOING,
        currency: '',
        participantId: '',
        description: '',
    })

    const [creatingStarted, setCreatingStarted] = useState(false)
    const [participant, setParticipant] = useState<GavialUserContact | null>(null)

    const state = useLocation();
    let backLinkTo = ".."
    if (state?.state !== null && state?.state["backLinkTo"]) {
        backLinkTo = state?.state["backLinkTo"]
    }
    let backLinkText = "Назад"
    if (state?.state !== null && state?.state["backLinkText"]) {
        backLinkText = state?.state["backLinkText"]
    }
    useEffect(() => {
        if (state?.state !== null && state?.state["userBalance"]) {
            const userBalance = state?.state["userBalance"] as GavialUserBalance
            const cents = Math.abs(parseFloat(userBalance.amount?.cents ?? '0'))

            setRequest({
                id: uuid(),
                type: (userBalance?.amount?.cents ?? 0) < 0 ? TransfersType.TYPE_OUTGOING : TransfersType.TYPE_INCOMING,
                currency: userBalance.amount?.currency,
                amount: cents.toString(),
                participantId: userBalance.user?.userId,
                description: (userBalance?.amount?.cents ?? 0) < 0 ? 'Возврат долга' : 'Списание долга',
            })

            setParticipant(userBalance.user ?? null)
        }
    }, [])

    useEffect(() => {
        if (request.participantId && !participant) {
            setParticipant(contacts.contactByID[request.participantId])
        }
    }, [contacts.loaded])

    useEffect(() => {
        if (state?.state == null && userInfo.loaded) {
            setRequest({
                ...request,
                currency: userInfo.currency ?? '',
            })
        }
    }, [dicts.currency.loaded, userInfo.loaded])

    const userInput = <TextField
        sx={{flexGrow: 1}}
        label={request.type === TransfersType.TYPE_OUTGOING ? 'Кто' : 'Кому'}
        value={userName(userInfo.user ?? {}) + ' (я)'}
        InputProps={{
            readOnly: true,
        }}
    />

    const participantInput = <Autocomplete
        sx={{flexGrow: 1}}
        autoComplete={false}
        // disablePortal
        options={contacts.contacts}
        noOptionsText={'нет подходящих вариантов'}
        getOptionLabel={(option) => userName(option)}
        renderInput={(params) => <TextField {...params}
                                            label={request.type === TransfersType.TYPE_OUTGOING ? 'Кому' : 'Кто'}/>
        }
        value={participant ?? null}
        onChange={function (event, value) {
            setParticipant(value)
            setRequest({
                ...request,
                participantId: value?.userId,
            })
        }}
    />

    if (creatingStarted && !transferCreation.isProcessing && transferCreation.error == null && transferCreation.created !== null) {
        return <Routes>
            <Route index element={<Navigate to={'/feed/'}/>}/>
        </Routes>
    }

    return <PageWithBackLink
        backLinkText={backLinkText}
        backLinkTo={backLinkTo}
        pageTitle={
            <Typography flexGrow={1} textAlign="center" variant="h6">Создание</Typography>
        }
    >
        <Box sx={{
            width: '100%',
            maxHeight: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            boxSizing: 'border-box',
            height: 'inherit',
        }}>
            <Stack spacing={1}>
                <Container sx={{mt: 2}}>
                    <Stack spacing={2}>
                        {
                            request.type === TransfersType.TYPE_OUTGOING ? userInput : participantInput
                        }
                        <Stack direction="row" alignItems="center" display="flex" spacing={1}>
                            <NumericFormat
                                customInput={TextField}
                                inputMode={'decimal'}
                                pattern="[0-9],*"
                                label='Сколько'
                                autoComplete='off'
                                thousandSeparator=' '
                                allowedDecimalSeparators={['.', ',']}
                                decimalScale={2}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                value={request?.amount ? +request?.amount / 100 : null}
                                onChange={function (event) {
                                    const cents = Math.round(parseFloat(event.target.value.replace(/\s/g, "")) * 100)
                                    setRequest({
                                        ...request,
                                        amount: cents + '',
                                    })
                                }}
                            />
                            <Autocomplete
                                autoComplete={false}
                                disablePortal
                                options={dicts.currency.items}
                                sx={{minWidth: 120}}
                                value={request.currency}
                                loading={dicts.currency.isProcessing}
                                renderInput={(params) => <TextField {...params} label="Валюта"/>}
                                onChange={(e, value) => {
                                    setRequest({
                                        ...request,
                                        currency: value ?? '',
                                    })
                                }}
                            />
                            <IconButton
                                color='primary'
                                onClick={() => {
                                    let newType = TransfersType.TYPE_OUTGOING
                                    if (request.type === newType) {
                                        newType = TransfersType.TYPE_INCOMING
                                    }
                                    setRequest({
                                        ...request,
                                        type: newType,
                                    })
                                }}
                            >
                                <SwapVert/>
                            </IconButton>
                        </Stack>
                        {
                            request.type === TransfersType.TYPE_OUTGOING ? participantInput : userInput
                        }
                        <TextField value={request.description} fullWidth label='Описание' autoComplete='off' multiline
                                   onChange={(e) => {
                                       setRequest({
                                           ...request,
                                           description: e.target.value,
                                       })
                                   }}
                        />
                        <LoadingButton loading={transferCreation.isProcessing}
                                       disabled={!(request.type && request.participantId && request.amount && request.currency)}
                                       loadingPosition='start'
                                       color='success'
                                       variant="contained" startIcon={<Send/>}
                                       onClick={() => {
                                           dispatch(createTransfer(request))
                                           setCreatingStarted(true)
                                       }}>
                            Создать
                        </LoadingButton>
                        {
                            transferCreation.error !== null &&
                            <Alert sx={{flex: 1}} severity="error">{transferCreation.error}</Alert>
                        }
                    </Stack>
                </Container>
            </Stack>
        </Box>
    </PageWithBackLink>
}

export default TransferCreatePage