import React, {FC, useEffect, useState} from "react";
import PageWithBackLink from "../common/PageWithBackLink";
import {Alert, Box, Button, CircularProgress, Container, IconButton, Stack} from "@mui/material";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import SearchInput from "../common/SearchInput";
import ContactEditPage from "./ContactEditPage";
import {useAppDispatch, useAppSelector} from "../../hook";
import UserAvatar from "../common/UserAvatar";
import UserName from "../common/UserName";
import {userName} from "../../utils/user";
import {Refresh} from "@mui/icons-material";
import {loadContacts} from "../../store/contacts";
import ContactDetailedPage from "./ContactDetailedPage";
import Typography from "@mui/material/Typography";

const ContactsPage: FC = () => {
    const dispatch = useAppDispatch()
    const contacts = useAppSelector(state => state.contacts.list)

    const navigate = useNavigate();

    const [filter, setFilter] = useState('')

    useEffect(function () {
        if (contacts.contacts.length === 0) {
            dispatch(loadContacts())
        }
    }, [])

    return <Container sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
        maxWidth: '100%',
    }} disableGutters>
        <Routes>
            <Route index element={
                <>
                    <Stack padding={2} spacing={2} direction="row">
                        <SearchInput value={filter} onFilterChange={(filter) => {
                            setFilter(filter)
                        }}/>
                        <Button component={Link} to="create" variant="text" color="primary" sx={{ml: 2}}>
                            Создать
                        </Button>
                    </Stack>
                    <Box sx={{
                        width: '100%',
                        maxHeight: 'inherit',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        boxSizing: 'border-box',
                        height: 'inherit',
                    }}>
                        <Container>
                            {
                                contacts.isProcessing &&
                                <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                                    <CircularProgress/>
                                </Box>
                            }
                            {
                                contacts.error != null &&
                                <Alert severity="error" action={
                                    <IconButton
                                        aria-label="reload"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            dispatch(loadContacts())
                                        }}
                                    >
                                        <Refresh fontSize="inherit"/>
                                    </IconButton>
                                }>
                                    {contacts.error}
                                </Alert>
                            }
                            {
                                !contacts.isProcessing && contacts.contacts.length > 0 &&
                                <Stack spacing={2} pb={2}>
                                    {contacts.contacts.map((contact) => {
                                        if (contact.isMe) {
                                            return null
                                        }
                                        if (filter !== '' && !userName(contact).toUpperCase().includes(filter.toUpperCase())) {
                                            return null
                                        }

                                        return <Stack key={contact.userId ?? ''} alignItems='center' direction='row'
                                                      spacing={1}
                                                      onClick={() => {
                                                          navigate(contact.userId ?? '')
                                                      }}
                                        >
                                            <UserAvatar id={contact.userId ?? ''}
                                                        firstName={contact.firstName}
                                                        lastName={contact.lastName}
                                                        username={contact.username}
                                                        isReal={contact.isReal}
                                            />
                                            <UserName user={contact}/>
                                        </Stack>
                                    })}
                                </Stack>
                            }
                        </Container>
                    </Box>
                </>
            }/>
            <Route path="create/*" element={
                <PageWithBackLink
                    backLinkText={"Назад"}
                    backLinkTo={".."}
                    pageTitle={
                        <Typography flexGrow={1} textAlign="center" variant="h6">Создание</Typography>
                    }
                >
                    <ContactEditPage backLinkTo={".."}/>
                </PageWithBackLink>
            }/>
            <Route path="/:id" element={
                <ContactDetailedPage/>
            }/>
        </Routes>
    </Container>
}

export default ContactsPage
