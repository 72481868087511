import React, {FC, ReactNode} from "react";
import {Box, Button, Divider, IconButton, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {KeyboardBackspace} from "@mui/icons-material";

interface PageWithBackLinkProps {
    backLinkTo?: string | null
    backLinkText?: string | null
    pageTitle?: ReactNode | null
    children: ReactNode
}

const PageWithBackLink: FC<PageWithBackLinkProps> = (
    {backLinkTo, backLinkText, pageTitle, children}
) => {
    const navigate = useNavigate();

    const goBack = () => {
        if (backLinkTo) {
            navigate(backLinkTo)
        } else {
            navigate(-1)
        }
    }

    let backLinkTextFormatted = backLinkText ? backLinkText : 'Назад'
    if (backLinkTextFormatted.length > 15) {
        backLinkTextFormatted = backLinkTextFormatted.substring(0, 14) + '...'
    }

    const pageTitleFormatted = pageTitle ? pageTitle : <div/>

    return <Box sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
    }}>
        <Stack flexDirection="row" alignItems={'center'}>
            <Button
                sx={{textTransform: "none", position: "fixed"}}
                startIcon={<KeyboardBackspace/>}
                onClick={goBack}
            >
                {backLinkTextFormatted}
            </Button>
            <Box sx={{minHeight: "46px"}} display="flex" alignItems="center" flexGrow={1}>
                {pageTitleFormatted}
            </Box>
        </Stack>
        <Divider/>
        <Box sx={{
            width: '100%',
            maxHeight: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            boxSizing: 'border-box',
            height: 'inherit',
            mb: 2,
        }}>
            {
                children
            }
        </Box>
    </Box>
}

export default PageWithBackLink