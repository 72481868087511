import React, {useEffect} from 'react';
import './index.css';
import {
    AppBar,
    Box,
    CircularProgress,
    Container,
    Divider,
    Drawer,
    IconButton,
    ListItem,
    ListItemButton,
    Stack
} from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
    AccountBalanceWallet,
    EventNote,
    Groups,
    Login,
    Logout,
    ReceiptLong,
    RecentActors,
    Send,
    Settings
} from "@mui/icons-material";
import ReceiptsPage from "./components/receipts/ReceiptsPage";
import {indigo} from '@mui/material/colors';
import {BrowserRouter as Router, Link, Navigate, Route, Routes} from 'react-router-dom'
import LoginPage from "./components/login/LoginPage";
import {useAppDispatch, useAppSelector} from "./hook";
import {authenticate, authFake, logout, tryRestoreAuthentication} from "./store/auth";
import SettingsPage from "./components/settings/SettingsPage";
import {loadUser} from "./store/user";
import ContactsPage from "./components/contacts/ContactsPage";
import {loadContacts} from "./store/contacts";
import GroupsPage from "./components/groups/GroupsPage";
import {loadGroups} from "./store/groups";
import {loadBalances} from "./store/balances";
import BalancesPage from "./components/balances/BalancesPage";
import TransfersPage from "./components/transfers/TrasnfersPage";
import {loadTransfers} from "./store/transfers";
import {loadCurrency} from "./store/dicts";
import {loadNews} from "./store/news";
import NewsPage from "./components/news/NewsPage";
import {loadReceipts} from "./store/receipts";
import {TelegramWebApps} from "telegram-webapps-types";
import {GavialTelegramAuthSource} from "./api/Api";

const App = () => {
    const [showDrawer, setShowDrawer] = React.useState(false);
    const auth = useAppSelector(state => state.auth)
    const user = useAppSelector(state => state.user.info)
    const contacts = useAppSelector(state => state.contacts.list)
    const groups = useAppSelector(state => state.groups.list)
    const balances = useAppSelector(state => state.balances.list)
    const transfers = useAppSelector(state => state.transfers.list)
    const receipts = useAppSelector(state => state.receipts.list)
    const news = useAppSelector(state => state.news.list)
    const currencies = useAppSelector(state => state.dicts.currency)
    const dispatch = useAppDispatch();
    const webApp = (window as any).Telegram.WebApp as TelegramWebApps.WebApp

    useEffect(() => {
        if (!auth.isAuthenticated) {
            dispatch(tryRestoreAuthentication())

        } else {
            dispatch(loadUser())
            dispatch(loadContacts())
            dispatch(loadGroups())
            dispatch(loadBalances())
            dispatch(loadReceipts())
        }
    }, [auth.isAuthenticated])

    useEffect(() => {
        if (auth.isRestoreEnd && !auth.isAuthenticated) {
            if (webApp.initData) {
                let authData: Record<string, string> = {}

                const authObj = Object.fromEntries(new URLSearchParams(webApp.initData))
                Object.keys(authObj).forEach((key) => {
                    authData[key] = authObj[key]
                })

                dispatch(authenticate({
                    authData: authData,
                    source: GavialTelegramAuthSource.TELEGRAM_AUTH_SOURCE_WEBAPP,
                }))
            }
        }
    }, [auth.isRestoreEnd])

    useEffect(() => {
        if (auth.isAuthenticated && !user.loaded) {
            dispatch(loadUser())
        }
    }, [auth.isAuthenticated, user.loaded])

    useEffect(() => {
        if (auth.isAuthenticated && !contacts.loaded) {
            dispatch(loadContacts())
        }
    }, [auth.isAuthenticated, contacts.loaded])

    useEffect(() => {
        if (auth.isAuthenticated && !groups.loaded) {
            dispatch(loadGroups())
        }
    }, [auth.isAuthenticated, groups.loaded])

    useEffect(() => {
        if (auth.isAuthenticated && !balances.loaded) {
            dispatch(loadGroups())
        }
    }, [auth.isAuthenticated, balances.loaded])

    useEffect(() => {
        if (auth.isAuthenticated && !transfers.loaded) {
            dispatch(loadTransfers())
        }
    }, [auth.isAuthenticated, transfers.loaded])

    useEffect(() => {
        if (auth.isAuthenticated && !receipts.loaded) {
            dispatch(loadReceipts())
        }
    }, [auth.isAuthenticated, receipts.loaded])

    useEffect(() => {
        if (auth.isAuthenticated && !news.loaded) {
            dispatch(loadNews())
        }
    }, [auth.isAuthenticated, news.loaded])

    useEffect(() => {
        if (auth.isAuthenticated && !currencies.loaded) {
            dispatch(loadCurrency())
        }
    }, [auth.isAuthenticated, currencies.loaded])

    useEffect(() => {
        setTimeout(() => {
            if (webApp.isExpanded) {
                return
            }
            webApp.expand()
        }, 500)
    }, [])

    const pageGroups = [
        [
            {
                text: "Контакты",
                icon: <RecentActors/>,
                routePath: "contacts",
                routeElement: <ContactsPage/>
            },
            {
                text: "Группы",
                icon: <Groups/>,
                routePath: "groups",
                routeElement: <GroupsPage/>
            }
        ],
        [
            {
                text: "Баланс",
                icon: <AccountBalanceWallet/>,
                routePath: "balance",
                routeElement: <BalancesPage/>
            },
            {
                text: "Лента",
                icon: <EventNote/>,
                routePath: "feed",
                routeElement: <NewsPage/>
            },
            {
                text: "Чеки",
                icon: <ReceiptLong/>,
                routePath: "receipts",
                routeElement: <ReceiptsPage/>
            },
            {
                text: "Переводы",
                icon: <Send/>,
                routePath: "transfers",
                routeElement: <TransfersPage/>
            }
        ],
        [
            {
                text: "Настройки",
                icon: <Settings/>,
                routePath: "settings",
                routeElement: <SettingsPage/>
            },
            {
                text: "Выйти",
                icon: <Logout/>,
                onClick: () => {
                    dispatch(logout())
                    if (webApp.initData) {
                        webApp.close()
                    }
                }
            },
        ]
    ]

    return (
        <Router>
            <Container maxWidth="sm" disableGutters={true} sx={{
                height: '100vh',
                maxHeight: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
            }}>
                <Box sx={{
                    width: '100%',
                    maxHeight: 'inherit',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    boxSizing: 'border-box',
                    height: 'inherit',
                }}>
                    <AppBar position="fixed"
                            sx={{zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: indigo["600"]}}>
                        <Toolbar>
                            {
                                auth.isAuthenticated &&
                                <IconButton
                                    disabled={false}
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{mr: 2, position: "fixed"}}
                                    onClick={() => {
                                        setShowDrawer(!showDrawer)
                                    }}
                                >
                                    <MenuIcon/>
                                </IconButton>
                            }
                            {
                                auth.isAuthenticated &&
                                <Box
                                    component="img"
                                    sx={{
                                        position: "fixed",
                                        right: "15px",
                                        top: "12px",
                                        height: 30,
                                        width: 30,
                                    }}
                                    alt="logo"
                                    src="/money.png"
                                    onClick={() => {
                                        window.location.reload()
                                    }}
                                />
                            }
                            <Routes>
                                {
                                    !auth.isAuthenticated &&
                                    <Route path="*" element={
                                        <Stack flexGrow={1}
                                               alignItems="center"
                                               justifyContent="center"
                                               spacing={1}
                                               direction="row"
                                        >
                                            <Stack alignItems="center" spacing={1} direction="row" onClick={() => {
                                                dispatch(authFake())
                                            }}>
                                                <Login/>
                                                <Typography variant="h6" component="div"
                                                            sx={{flexGrow: 1}}>
                                                    Monies Space
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    }/>
                                }
                                {
                                    auth.isAuthenticated &&
                                    <Route path="/">
                                        {
                                            pageGroups.map((pageGroup, index) => {
                                                return pageGroup.map((page) => {
                                                    return <Route path={page.routePath + "/*"} element={
                                                        <Stack flexGrow={1}
                                                               alignItems="center"
                                                               justifyContent="center"
                                                               spacing={1}
                                                               direction="row"
                                                        >
                                                            <Stack alignItems="center" spacing={1} direction="row"
                                                                   onClick={() => {
                                                                       setShowDrawer(!showDrawer)
                                                                   }}
                                                            >
                                                                {page.icon}
                                                                <Typography variant="h6" component="div"
                                                                            sx={{flexGrow: 1}}>
                                                                    {page.text}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    }/>
                                                })
                                            })
                                        }
                                    </Route>
                                }
                            </Routes>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        anchor='left'
                        open={showDrawer}
                        onClose={() => {
                            setShowDrawer(false)
                        }}
                    >
                        <Toolbar/>
                        <Box
                            sx={{width: 250}}
                            role="presentation"
                            onClick={() => {
                                setShowDrawer(false)
                            }}
                            onKeyDown={() => {
                                setShowDrawer(false)
                            }}
                        >
                            <List>
                                {pageGroups.map((pageGroup, groupIndex) => {
                                    return <div key={groupIndex}>
                                        {pageGroup.map((page, pageIndex) => {
                                            let props = {}
                                            if (page.routePath) {
                                                props = {
                                                    component: Link,
                                                    to: page.routePath
                                                }
                                            }
                                            if (page.onClick) {
                                                props = {onClick: page.onClick}
                                            }

                                            return <ListItem key={groupIndex + '.' + pageIndex}>
                                                <ListItemButton
                                                    {...props}
                                                >
                                                    <ListItemIcon>
                                                        {page.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={page.text}/>
                                                </ListItemButton>
                                            </ListItem>
                                        })}
                                        {
                                            groupIndex < pageGroups.length - 1 &&
                                            <Divider/>
                                        }
                                    </div>
                                })}
                            </List>
                        </Box>
                    </Drawer>
                    <Box sx={{
                        width: '100%',
                        maxHeight: 'inherit',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        boxSizing: 'border-box',
                        height: 'inherit',
                    }}
                    >
                        <Toolbar/>
                        <Box sx={{
                            width: '100%',
                            maxHeight: 'inherit',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'auto',
                            boxSizing: 'border-box',
                            height: 'inherit',
                        }}>
                            <Routes>
                                {
                                    !auth.isAuthenticated &&
                                    <Route path="*" element={
                                        auth.isProcessing ?
                                            <Box border={1} justifyContent='center' flexGrow={1} sx={{
                                                display: 'flex',
                                                maxHeight: 'inherit',
                                                boxSizing: 'border-box',
                                                alignItems: 'center'
                                            }}>
                                                <CircularProgress/>
                                            </Box>
                                            : <LoginPage/>
                                    }/>
                                }
                                {
                                    auth.isAuthenticated && !auth.isProcessing &&
                                    <Route index element={<Navigate to={"/balance"}/>}/>
                                }
                                {
                                    auth.isAuthenticated && !auth.isProcessing &&
                                    <Route path="/">
                                        {pageGroups.map((pageGroup) => {
                                            return pageGroup.map((page) => {
                                                return <Route
                                                    path={"/" + page.routePath + "/*"}
                                                    element={page.routeElement}
                                                />
                                            })
                                        })}
                                        <Route path="*" element={<Navigate to={"/balance"}/>}/>
                                    </Route>
                                }
                            </Routes>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Router>
    )
};

export default App;