import React, {FC, useEffect, useState} from "react";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    IconButton,
    Stack,
    Switch,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    ConsumptionsType,
    DiscountsType,
    GavialConsumption,
    GavialConsumptionInput,
    GavialDiscount,
    GavialDiscountInput,
    GavialPayment,
    GavialPaymentInput,
    GavialPosition,
    GavialPositionInput,
    GavialReceipt,
    GavialReceiptInput,
    GavialSaveReceiptRequest,
    GavialUserContact,
    ReceiptsStatus
} from "../../api/Api";
import {v4 as uuid} from "uuid";
import {useAppDispatch, useAppSelector} from "../../hook";
import {Add, AddCircleOutline, ReceiptLong, Refresh, RemoveCircleOutline} from "@mui/icons-material";
import PositionEditPanel from "./PositionEditPanel";
import LoadingButton from "@mui/lab/LoadingButton";
import {loadReceipt, processReceipt, saveReceipt} from "../../store/receipts";
import DiscountEditPanel from "./DiscountEditPanel";
import {userName} from "../../utils/user";
import {NumericFormat} from "react-number-format";
import {useNavigate, useParams} from "react-router-dom";

interface ReceiptEditPageProps {
    receiptID?: string
}

const ReceiptEditPage: FC<ReceiptEditPageProps> = ({receiptID}) => {
    const userInfo = useAppSelector(state => state.user.info)
    const dicts = useAppSelector(state => state.dicts)
    const saving = useAppSelector(state => state.receipts.saving)
    const process = useAppSelector(state => state.receipts.process)
    const receiptState = useAppSelector(state => state.receipts.detailed)
    const contacts = useAppSelector(state => state.contacts.list)
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const {id} = useParams();
    if (id) {
        receiptID = id
    }

    const isNewReceipt = !receiptID

    const [discountEnabled, setDiscountEnabled] = useState(false)
    const [request, setRequest] = useState<GavialSaveReceiptRequest>({
        receipt: newReceipt(''),
    })
    const [receipt, setReceipt] = useState<GavialReceipt | null>(null)
    const [wasEdited, setWasEdited] = useState<boolean>(false)

    function updateReceiptRequest(request: GavialSaveReceiptRequest, wasEdited: boolean) {
        setRequest(request)
        setWasEdited(wasEdited)
    }

    useEffect(function () {
        if (!isNewReceipt) {
            dispatch(loadReceipt(receiptID ?? ''))
        }
    }, [])

    useEffect(() => {
        if (saving.saved && saving.saved.id === request?.receipt?.id) {
            setReceipt(saving.saved)
            updateReceiptRequest(receiptToRequest(saving.saved), false)

            if (isNewReceipt) {
                navigate('/receipts/' + saving.saved.id)
            }
        }
    }, [saving.saved])

    useEffect(() => {
        if (process.processed && process.processed.id === request?.receipt?.id) {
            navigate('/receipts/'+request?.receipt?.id+'/show')
        }
    }, [process.processed])

    useEffect(() => {
        if (receiptState.loaded) {
            setReceipt(receiptState.item)
            updateReceiptRequest(receiptToRequest(receiptState.item ?? {}), false)
        }
    }, [receiptState.loaded])

    useEffect(() => {
        if (receipt === null && userInfo.loaded) {
            updateReceiptRequest({
                receipt: {
                    ...request.receipt,
                    currency: userInfo.currency ?? ''
                }
            }, false)
        }
    }, [dicts.currency.loaded, userInfo.loaded])

    const loading = receiptState.isProcessing ||
        dicts.currency.isProcessing ||
        userInfo.isLoading ||
        contacts.isProcessing

    return <Box sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
    }}>
        {
            loading &&
            <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box>
        }
        {
            receiptState.error != null &&
            <Alert severity="error" action={
                <IconButton
                    aria-label="reload"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        dispatch(loadReceipt(receiptID ?? ''))
                    }}
                >
                    <Refresh fontSize="inherit"/>
                </IconButton>
            }>
                {receiptState.error}
            </Alert>
        }
        {
            !loading &&
            <>
                <Container sx={{
                    width: '100%',
                    maxHeight: 'inherit',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    boxSizing: 'border-box',
                    height: 'inherit',
                }}>
                    <Stack mt={2} spacing={1}>
                        <Stack direction="row" spacing={1}>
                            <TextField autoComplete="off" variant="outlined" label="Название чека" sx={{flexGrow: 1}}
                                       value={request.receipt?.title}
                                       onChange={(event) => {
                                           updateReceiptRequest({
                                               receipt: {
                                                   ...request.receipt,
                                                   title: event.target.value
                                               }
                                           }, true)
                                       }}
                            />
                            <Autocomplete
                                autoComplete={false}
                                // disablePortal
                                options={dicts.currency.items}
                                sx={{minWidth: 120}}
                                value={request?.receipt?.currency ?? null}
                                loading={dicts.currency.isProcessing}
                                renderInput={(params) => <TextField {...params} label="Валюта"/>}
                                onChange={(e, value) => {
                                    const requestCopy: GavialSaveReceiptRequest = {
                                        receipt: {
                                            ...request.receipt,
                                            currency: value ?? undefined
                                        }
                                    }
                                    updateReceiptRequest(requestCopy, true)
                                }}
                            />
                        </Stack>
                        <Stack spacing={0.5}>
                            <Typography variant="subtitle1" fontWeight={'bold'}>Позиции</Typography>
                            {
                                request.receipt?.positions?.map((position, index) => {
                                    position.index = index + 1

                                    return <Stack key={index} spacing={1}>
                                        <Divider/>
                                        <PositionEditPanel positionRequest={position}
                                                           onChange={(position) => {
                                                               request.receipt?.positions?.fill(position, index, index + 1)
                                                               updateReceiptRequest({...request}, true)
                                                           }}
                                                           onRemove={() => {
                                                               request.receipt?.positions?.splice(index, 1)

                                                               request.receipt?.positions?.forEach((position, index) => {
                                                                   position.index = index + 1
                                                               })

                                                               updateReceiptRequest({...request}, true)
                                                           }}
                                        />
                                    </Stack>
                                })
                            }
                            <Divider/>
                            <Box>
                                <Button size={'small'} startIcon={<Add/>}
                                        variant={'outlined'}
                                        sx={{
                                            mb: 2,
                                            mt: 1,
                                        }}
                                        onClick={() => {
                                            const index = (request.receipt?.positions?.length ?? 0) + 1
                                            request.receipt?.positions?.push(newPosition(index))

                                            updateReceiptRequest({
                                                receipt: {
                                                    ...request.receipt,
                                                    positions: request.receipt?.positions,
                                                }
                                            }, true)
                                        }}
                                >
                                    позиция
                                </Button>
                            </Box>
                        </Stack>
                        <Stack spacing={0.5}>
                            <Stack alignItems={'center'} direction={'row'}>
                                <Typography variant="subtitle1" fontWeight={'bold'}>Скидки</Typography>
                                <Switch sx={{mt: 0.4}} onChange={(e, checked) => {
                                    let discounts: GavialDiscountInput[] = []
                                    if (checked) {
                                        discounts = [newDiscount(1)]
                                    }

                                    updateReceiptRequest({
                                        receipt: {
                                            ...request.receipt,
                                            discounts: discounts,
                                        }
                                    }, true)

                                    setDiscountEnabled(checked)
                                }}/>
                            </Stack>
                            <Stack spacing={1}>
                                {
                                    request.receipt?.discounts?.map((discount, index) => {
                                        discount.index = index + 1

                                        return <Stack key={index} spacing={1}>
                                            <Divider/>
                                            <DiscountEditPanel discount={discount}
                                                               currency={request.receipt?.currency ?? ''}
                                                               positions={request.receipt?.positions ?? []}
                                                               onChange={(discount) => {
                                                                   request.receipt?.discounts?.fill(discount, index, index + 1)
                                                                   updateReceiptRequest({...request}, true)
                                                               }}
                                                               onRemove={() => {
                                                                   request.receipt?.discounts?.splice(index, 1)

                                                                   request.receipt?.discounts?.forEach((position, index) => {
                                                                       position.index = index + 1
                                                                   })

                                                                   updateReceiptRequest({...request}, true)
                                                               }}
                                            />
                                        </Stack>
                                    })
                                }
                            </Stack>
                            {
                                discountEnabled &&
                                <>
                                    <Divider sx={{mt: 2}}/>
                                    <Box>
                                        <Button size={'small'} startIcon={<Add/>}
                                                variant={'outlined'}
                                                sx={{
                                                    mb: 2,
                                                    mt: 1,
                                                }}
                                                onClick={() => {
                                                    const index = (request.receipt?.discounts?.length ?? 0) + 1
                                                    request.receipt?.discounts?.push(newDiscount(index))

                                                    updateReceiptRequest({...request}, true)
                                                }}
                                        >
                                            скидка
                                        </Button>
                                    </Box>
                                </>
                            }
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant="subtitle1" fontWeight={'bold'}>Оплаты</Typography>
                            {
                                request.receipt?.payments?.map((payment, i) => {
                                    let user: GavialUserContact | null = null
                                    if (payment.userId) {
                                        user = contacts.contactByID[payment.userId]
                                    }

                                    return <Stack key={i} direction={'row'} spacing={1}>
                                        <Autocomplete
                                            sx={{flexGrow: 1}}
                                            disablePortal={true}
                                            autoComplete={false}
                                            options={contacts.contacts}
                                            noOptionsText={'нет подходящих вариантов'}
                                            getOptionLabel={(option) => userName(option)}
                                            renderInput={(params) => <TextField label="Кто платил" {...params}/>
                                            }
                                            value={user}
                                            onChange={function (event, value) {
                                                payment.userId = value?.userId

                                                request.receipt?.payments?.fill(payment, i, i + 1)
                                                updateReceiptRequest({...request}, true)
                                            }}
                                        />
                                        <NumericFormat
                                            customInput={TextField}
                                            inputMode={'decimal'}
                                            sx={{maxWidth: 100}}
                                            label='Сколько'
                                            autoComplete='off'
                                            thousandSeparator=' '
                                            allowedDecimalSeparators={['.', ',']}
                                            decimalScale={2}
                                            allowNegative={false}
                                            allowLeadingZeros={false}
                                            value={payment?.amount ? +payment?.amount / 100 : null}
                                            onChange={function (event) {
                                                const cents = Math.round(parseFloat(event.target.value.replace(/\s/g, "")) * 100)
                                                payment.amount = cents + ''

                                                request.receipt?.payments?.fill(payment, i, i + 1)
                                                updateReceiptRequest({...request}, true)
                                            }}
                                        />
                                        <IconButton sx={{ml: 0, p: 0}} size={'small'} color='primary' onClick={() => {
                                            request.receipt?.payments?.splice(i, 1)
                                            request.receipt?.payments?.forEach((payment, index) => {
                                                payment.index = index + 1
                                            })

                                            updateReceiptRequest({...request}, true)
                                        }}
                                        >
                                            <RemoveCircleOutline/>
                                        </IconButton>
                                    </Stack>
                                })
                            }
                            <Button size={'large'} startIcon={<AddCircleOutline/>}
                                    sx={{textTransform: "none"}}
                                    onClick={() => {
                                        request.receipt?.payments?.push({
                                            id: uuid(),
                                            index: request.receipt?.payments?.length + 1,
                                        })
                                        updateReceiptRequest({...request}, true)
                                    }}
                            >
                                Добавить
                            </Button>
                        </Stack>
                    </Stack>
                </Container>
                {
                    receipt?.hints && receipt?.hints?.length > 0 &&
                    <>
                        <Divider/>
                        <Stack spacing={0.5} ml={2} mr={2} mt={1} mb={1}>
                            <Typography color={'info.main'}>
                                {
                                    receipt.hints.join('; ')
                                }
                            </Typography>
                        </Stack>
                    </>
                }
                {
                    (wasEdited || (receipt !== null && receipt?.status === ReceiptsStatus.STATUS_READY_TO_PROCESS)) &&
                    <>
                        <Divider/>
                        <Stack direction={'row'} spacing={1} ml={1} mr={1} mt={2}>
                            {
                                wasEdited &&
                                <Stack flexGrow={1} spacing={1}>
                                    <LoadingButton
                                        loading={saving.isProcessing}
                                        loadingPosition='start'
                                        color='primary'
                                        variant={'contained'}
                                        startIcon={<ReceiptLong/>}
                                        onClick={() => {
                                            console.log(request)
                                            dispatch(saveReceipt(sanitizeRequest(request)))
                                        }}>
                                        {isNewReceipt ? 'Создать' : 'Сохранить'}
                                    </LoadingButton>
                                    {
                                        saving.error !== null &&
                                        <Alert sx={{flex: 1}} severity="error">{saving.error}</Alert>
                                    }
                                </Stack>
                            }
                            {
                                !wasEdited && receipt !== null && receipt?.status === ReceiptsStatus.STATUS_READY_TO_PROCESS &&
                                <Stack flexGrow={1} spacing={1}>
                                    <LoadingButton
                                        loading={process.isProcessing}
                                        loadingPosition='start'
                                        color='success'
                                        variant={'contained'}
                                        startIcon={<ReceiptLong/>}
                                        onClick={() => {
                                            dispatch(
                                                processReceipt({
                                                    id: receipt?.id,
                                                    version: receipt?.version
                                                })
                                            )
                                        }}>
                                        Обработать
                                    </LoadingButton>
                                    {
                                        process.error !== null &&
                                        <Alert sx={{flex: 1}} severity="error">{process.error}</Alert>
                                    }
                                </Stack>
                            }
                        </Stack>
                    </>
                }
            </>
        }
    </Box>
}

function newReceipt(currency: string): GavialReceiptInput {
    return {
        id: uuid(),
        version: 0,
        title: '',
        currency: currency,
        positions: [newPosition(1)],
        payments: [newPayment(1)],
    }
}

function newPosition(index: number): GavialPositionInput {
    return {
        id: uuid(),
        index: index,
        title: '',
        consumptionType: ConsumptionsType.TYPE_EQUALITY,
        consumptions: [{
            id: uuid(),
            index: 1,
            type: ConsumptionsType.TYPE_EQUALITY,
            paramsEquality: {},
        }],
    }
}

function newDiscount(index: number): GavialDiscountInput {
    return {
        id: uuid(),
        index: index,
        type: DiscountsType.TYPE_PERCENTAGES,
        paramsPercentages: {percentages: 0},
        allPositions: true,
    }
}

function newPayment(index: number): GavialPaymentInput {
    return {
        id: uuid(),
        index: index,
    }
}

export default ReceiptEditPage

function receiptToRequest(receipt: GavialReceipt): GavialSaveReceiptRequest {
    return {
        receipt: {
            id: receipt.id,
            version: receipt.version,
            title: receipt.title,
            currency: receipt.currency,
            positions: receipt.positions?.map(positionToRequest),
            discounts: receipt.discounts?.map(discountToRequest),
            payments: receipt.payments?.map(paymentToRequest),
        }
    }
}

function positionToRequest(position: GavialPosition): GavialPositionInput {
    return {
        id: position.id,
        index: position.index,
        title: position.title,
        amount: sanitizeAmount(position.amount?.cents),
        consumptionType: position.consumptionType,
        consumptions: position.consumptions?.map(consumptionToRequest)
    }
}

function consumptionToRequest(consumption: GavialConsumption): GavialConsumptionInput {
    let req: GavialConsumptionInput = {
        id: consumption.id,
        index: consumption.index,
        userId: consumption.user?.userId,
        type: consumption.type,
        paramsEquality: consumption.paramsEquality,
        paramsPieces: consumption.paramsPieces,
    }
    if (consumption.paramsExactAmount) {
        req.paramsExactAmount = {amount: sanitizeAmount(consumption.paramsExactAmount.amount?.cents)}
    }
    if (consumption.paramsAdjustment) {
        req.paramsAdjustment = {adjustmentAmount: sanitizeAmount(consumption.paramsAdjustment.adjustmentAmount?.cents)}
    }

    return req
}

function discountToRequest(discount: GavialDiscount): GavialDiscountInput {
    let req: GavialDiscountInput = {
        id: discount.id,
        index: discount.index,
        allPositions: discount.allPositions,
        positions: discount.positions,
        type: discount.type,
        paramsPercentages: discount.paramsPercentages,
    }
    if (discount.paramsExactAmount) {
        req.paramsExactAmount = {
            amount: sanitizeAmount(discount.paramsExactAmount.amount?.cents)
        }
    }

    return req
}

function paymentToRequest(payment: GavialPayment): GavialPaymentInput {
    return {
        id: payment.id,
        index: payment.index,
        userId: payment.user?.userId,
        amount: sanitizeAmount(payment.amount?.cents),
    }
}

function sanitizeAmount(amount: string | undefined): string | undefined {
    if (amount === "0") {
        return undefined
    }

    return amount
}

function sanitizeRequest(request: GavialSaveReceiptRequest): GavialSaveReceiptRequest {
    return {
        ...request,
        receipt: {
            ...request.receipt,
            positions: request.receipt?.positions?.map((position) => {
                position.consumptions = position.consumptions?.filter(c => c.userId !== undefined)
                return position
            }),
            payments: request.receipt?.payments?.filter(p => p.userId !== undefined),
        }
    }
}