import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    GavialAddContactResponse,
    GavialCreateDraftUserContactRequest,
    GavialCreateDraftUserContactResponse,
    GavialRemoveContactResponse,
    GavialUserContact, GavialUserContactDetailed
} from "../api/Api";
import api from "../apiClient/api";
import {userName} from "../utils/user";
import {rollbackReceipt} from "./receipts";
import {updateUser} from "./user";
import {joinGroup, leaveGroup} from "./groups";

interface ContactCreationState {
    isProcessing: boolean
    error: string | null
    createdContact: GavialUserContact | null
}

interface ContactsListState {
    loaded: boolean
    isProcessing: boolean
    error: string | null
    contacts: GavialUserContact[]
    contactByID: Record<string, GavialUserContact>
}

interface ContactCreationState {
    isProcessing: boolean
    error: string | null
    createdContact: GavialUserContact | null
}

interface ContactRemovalState {
    isProcessing: boolean
    error: string | null
    deletedContactId: string | null
}

interface ContactDetailed {
    contact: GavialUserContactDetailed | null
    isLoading: boolean
    error: string | null
}

interface ContactDetailedListState {
    contacts: Record<string, ContactDetailed>
}

interface ContactAdditionState {
    isProcessing: boolean
    error: string | null
    addedContact: GavialUserContact | null
}

type ContactsState = {
    list: ContactsListState
    creation: ContactCreationState
    removal: ContactRemovalState
    detailed: ContactDetailedListState
    addition: ContactAdditionState
}

const initialState: ContactsState = {
    list: {
        loaded: false,
        isProcessing: false,
        error: null,
        contacts: [],
        contactByID: {},
    },
    creation: {
        isProcessing: false,
        error: null,
        createdContact: null,
    },
    removal: {
        isProcessing: false,
        error: null,
        deletedContactId: null,
    },
    detailed: {
        contacts: {}
    },
    addition: {
        isProcessing: false,
        error: null,
        addedContact: null,
    }
}

export const createDraftUserContact = createAsyncThunk<GavialCreateDraftUserContactResponse, GavialCreateDraftUserContactRequest, { rejectValue: string }>(
    'createDraftUserContact',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.contacts.serviceCreateDraftUserContact(request, {
                headers: {}
            })

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка создания контакта')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка создания контакта')
        }
    }
)

export const removeUserContact = createAsyncThunk<GavialRemoveContactResponse, string, { rejectValue: string }>(
    'removeUserContact',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.contacts.serviceRemoveContact(request)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка удаления контакта')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка удаления контакта')
        }
    }
)

export const addUserContact = createAsyncThunk<GavialAddContactResponse, string, { rejectValue: string }>(
    'addUserContact',
    async function (id, {rejectWithValue}) {
        try {
            const resp = await api.contacts.serviceAddContact(id)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка добавления контакта')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка добавления контакта')
        }
    }
)

export const loadContacts = createAsyncThunk<GavialUserContact[], undefined, { rejectValue: string }>(
    'loadContacts',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.contacts.serviceListContacts()

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки контактов')
            }

            return resp.data.items ?? []
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки контактов')
        }
    }
)

export const loadContactDetailed = createAsyncThunk<GavialUserContactDetailed, string, { rejectValue: string }>(
    'loadContactDetailed',
    async function (id, {rejectWithValue}) {
        try {
            const resp = await api.contacts.serviceGetContactDetailed(id)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки контакта')
            }

            return resp.data.contact ?? {}
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки контакта')
        }
    }
)

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createDraftUserContact.pending, (state) => {
                state.creation.isProcessing = true
                state.creation.error = null
            })
            .addCase(createDraftUserContact.fulfilled, (state, action) => {
                clearState(state)
                state.creation.isProcessing = false
                state.creation.createdContact = action.payload.user ?? {}
            })
            .addCase(createDraftUserContact.rejected, (state, action) => {
                state.creation.isProcessing = false
                state.creation.error = action.payload ?? null
            })
            .addCase(loadContacts.pending, (state) => {
                state.list.isProcessing = true
                state.list.error = null
            })
            .addCase(loadContacts.fulfilled, (state, action) => {
                state.list.isProcessing = false
                state.list.contacts = action.payload

                state.list.contactByID = {}
                state.list.contacts.forEach(c => state.list.contactByID[c.userId ?? ''] = c)

                sortContacts(state.list.contacts)
                state.list.loaded = true
            })
            .addCase(loadContacts.rejected, (state, action) => {
                state.list.isProcessing = false
                state.list.error = action.payload ?? null
            })
            .addCase(removeUserContact.pending, (state) => {
                state.removal.isProcessing = true
                state.removal.error = null
            })
            .addCase(removeUserContact.fulfilled, (state, action) => {
                clearState(state)
                state.removal.isProcessing = false
                state.removal.deletedContactId = action.meta.arg
            })
            .addCase(removeUserContact.rejected, (state, action) => {
                state.removal.isProcessing = false
                state.removal.error = action.payload ?? null
            })
            .addCase(loadContactDetailed.pending, (state, action) => {
                state.detailed.contacts[action.meta.arg] = {
                    isLoading: true,
                    error: null,
                    contact: null,
                }
            })
            .addCase(loadContactDetailed.fulfilled, (state, action) => {
                state.detailed.contacts[action.payload.user?.id ?? ''] = {
                    isLoading: false,
                    error: null,
                    contact: action.payload,
                }
            })
            .addCase(loadContactDetailed.rejected, (state, action) => {
                state.detailed.contacts[action.meta.arg] = {
                    isLoading: false,
                    contact: null,
                    error: action.payload ?? null,
                }
            })
            .addCase(addUserContact.pending, (state, action) => {
                state.addition = {
                    isProcessing: true,
                    error: null,
                    addedContact: null,
                }
            })
            .addCase(addUserContact.fulfilled, (state, action) => {
                clearState(state)
                state.addition = {
                    isProcessing: false,
                    error: null,
                    addedContact: action.payload.contact ?? null,
                }
            })
            .addCase(addUserContact.rejected, (state, action) => {
                state.addition = {
                    isProcessing: false,
                    error: action.payload ?? '',
                    addedContact: null,
                }
            })
            .addCase(joinGroup.fulfilled, (state, action) => {
                clearState(state)
            })
            .addCase(leaveGroup.fulfilled, (state, action) => {
                clearState(state)
            })
    }
})

export default contactsSlice.reducer;

function sortContacts(contacts: GavialUserContact[]) {
    contacts.sort((a, b): number => {
        if (a.isMe) {
            return -1
        }
        if (b.isMe) {
            return 1
        }

        return userName(a).toUpperCase() < userName(b).toUpperCase() ? -1 : 1
    })
}

function clearState(state: ContactsState) {
    state.list.contacts = []
    state.list.loaded = false
    state.detailed.contacts = {}
}