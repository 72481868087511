import {
    GavialProcessReceiptRequest,
    GavialReceipt,
    GavialReceiptItem, GavialRollbackReceiptRequest,
    GavialSaveReceiptRequest,
    GavialSaveReceiptResponse
} from "../api/Api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "../apiClient/api";
import {updateUser} from "./user";

interface ReceiptsSavingState {
    isProcessing: boolean
    error: string | null
    saved: GavialReceipt | null
}

interface ReceiptListState {
    loaded: boolean
    isProcessing: boolean
    error: string | null
    items: GavialReceiptItem[]
}

interface ReceiptDetailedState {
    loaded: boolean
    isProcessing: boolean
    error: string | null
    item: GavialReceipt | null
}

interface ReceiptProcessingState {
    isProcessing: boolean
    error: string | null
    processed: GavialReceipt | null
}

interface ReceiptRollbackState {
    isProcessing: boolean
    error: string | null
    item: GavialReceipt | null
}

type ReceiptsState = {
    list: ReceiptListState
    saving: ReceiptsSavingState
    detailed: ReceiptDetailedState
    process: ReceiptProcessingState
    rollback: ReceiptRollbackState
}

const initialState: ReceiptsState = {
    list: {
        loaded: false,
        isProcessing: false,
        error: null,
        items: [],
    },
    saving: {
        isProcessing: false,
        error: null,
        saved: null,
    },
    detailed: {
        loaded: false,
        isProcessing: false,
        error: null,
        item: null,
    },
    process: {
        isProcessing: false,
        error: null,
        processed: null,
    },
    rollback: {
        isProcessing: false,
        error: null,
        item: null,
    },
}

export const loadReceipt = createAsyncThunk<GavialReceipt, string, { rejectValue: string }>(
    'loadReceipt',
    async function (id, {rejectWithValue}) {
        try {
            const resp = await api.receipts.serviceGetReceipt(id)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки')
            }

            return resp.data.receipt ?? {}
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки')
        }
    }
)

export const loadReceipts = createAsyncThunk<GavialReceipt[], undefined, { rejectValue: string }>(
    'loadReceipts',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.receipts.serviceListReceipts()

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки')
            }

            return resp.data.items ?? []
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки')
        }
    }
)

export const saveReceipt = createAsyncThunk<GavialSaveReceiptResponse, GavialSaveReceiptRequest, {
    rejectValue: string
}>(
    'saveReceipt',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.receipts.serviceSaveReceipt(request, {
                headers: {}
            })

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка сохранения')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка сохранения')
        }
    }
)

export const processReceipt = createAsyncThunk<GavialReceipt, GavialProcessReceiptRequest, {
    rejectValue: string
}>(
    'processReceipt',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.receipts.serviceProcessReceipt(request)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка')
            }

            return resp.data.receipt ?? {}
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка')
        }
    }
)

export const rollbackReceipt = createAsyncThunk<GavialReceipt, GavialRollbackReceiptRequest, {
    rejectValue: string
}>(
    'rollbackReceipt',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.receipts.serviceRollbackReceipt(request)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка')
            }

            return resp.data.receipt ?? {}
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка')
        }
    }
)

const receiptsSlice = createSlice({
    name: 'receipts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveReceipt.pending, (state) => {
                state.saving.isProcessing = true
                state.saving.error = null
            })
            .addCase(saveReceipt.fulfilled, (state, action) => {
                clearState(state)
                state.saving.isProcessing = false
                state.saving.saved = action.payload.receipt ?? {}
            })
            .addCase(saveReceipt.rejected, (state, action) => {
                state.saving.isProcessing = false
                state.saving.error = action.payload ?? null
            })
            .addCase(processReceipt.pending, (state) => {
                state.process.isProcessing = true
                state.process.error = null
            })
            .addCase(processReceipt.fulfilled, (state, action) => {
                clearState(state)
                state.process.isProcessing = false
                state.process.processed = action.payload
            })
            .addCase(processReceipt.rejected, (state, action) => {
                state.process.isProcessing = false
                state.process.error = action.payload ?? null
            })
            .addCase(rollbackReceipt.pending, (state) => {
                state.rollback.isProcessing = true
                state.rollback.error = null
            })
            .addCase(rollbackReceipt.fulfilled, (state, action) => {
                clearState(state)
                state.rollback.isProcessing = false
                state.rollback.item = action.payload
            })
            .addCase(rollbackReceipt.rejected, (state, action) => {
                state.rollback.isProcessing = false
                state.rollback.error = action.payload ?? null
            })
            .addCase(loadReceipts.pending, (state) => {
                state.list.isProcessing = true
                state.list.error = null
            })
            .addCase(loadReceipts.fulfilled, (state, action) => {
                state.list.isProcessing = false
                state.list.items = action.payload
                state.list.loaded = true
            })
            .addCase(loadReceipts.rejected, (state, action) => {
                state.list.isProcessing = false
                state.list.error = action.payload ?? null
            })
            .addCase(loadReceipt.pending, (state) => {
                state.detailed.loaded = false
                state.detailed.item = null
                state.detailed.isProcessing = true
                state.detailed.error = null
            })
            .addCase(loadReceipt.fulfilled, (state, action) => {
                state.detailed.loaded = true
                state.detailed.isProcessing = false
                state.detailed.item = action.payload

                state.saving.error = null
                state.rollback = {
                    isProcessing: false,
                    error: null,
                    item: null,
                }
            })
            .addCase(loadReceipt.rejected, (state, action) => {
                state.detailed.loaded = false
                state.detailed.isProcessing = false
                state.detailed.error = action.payload ?? null
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                clearState(state)
            })
    }
})

function clearState(state: ReceiptsState) {
    state.list.items = []
    state.list.loaded = false
    state.detailed.item = null
    state.detailed.loaded = false
}

export default receiptsSlice.reducer;