import React, {FC, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hook";
import {v4 as uuid} from "uuid";
import {Navigate, Route, Routes} from "react-router-dom";
import {Alert, Box, Container, Stack, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {GroupAdd} from "@mui/icons-material";
import {GavialCreateGroupRequest} from "../../api/Api";
import {createGroup} from "../../store/groups";

interface GroupCreatePageProps {
    backLinkTo: string
}

const GroupCreatePage: FC<GroupCreatePageProps> = ({backLinkTo}) => {
    const dispatch = useAppDispatch()
    const creation = useAppSelector(state => state.groups.creation)

    const [creatingStarted, setCreatingStarted] = useState(false)
    const [request, setRequest] = useState<GavialCreateGroupRequest>({
        id: uuid(),
    })

    if (creatingStarted && !creation.isProcessing && creation.error == null && creation.created !== null) {
        return <Routes>
            <Route index element={<Navigate to={'/groups/' + creation.created.id}/>}/>
        </Routes>
    }

    return <Box sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
    }}>
        <Stack mt={2} spacing={1}>
            <Container>
                <Stack spacing={2}>
                    <Stack direction="row" spacing={1}>
                        <TextField required autoComplete="off" variant="outlined" label="Название" sx={{flexGrow: 1}}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                       setRequest({
                                           ...request,
                                           title: e.target.value,
                                       })
                                   }}
                        />
                    </Stack>
                    <LoadingButton loading={creation.isProcessing}
                                   disabled={!request.title}
                                   loadingPosition='start'
                                   color='success'
                                   variant="contained" startIcon={<GroupAdd/>}
                                   onClick={() => {
                                       dispatch(createGroup(request))
                                       setCreatingStarted(true)
                                   }}>
                        Создать
                    </LoadingButton>
                    {
                        creation.error !== null &&
                        <Alert sx={{flex: 1}} severity="error">{creation.error}</Alert>
                    }
                </Stack>
            </Container>
        </Stack>
    </Box>
}

export default GroupCreatePage