import React, {FC, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hook";
import {loadNews} from "../../store/news";
import {Alert, Box, CircularProgress, Container, IconButton, Stack} from "@mui/material";
import SearchInput from "../common/SearchInput";
import {Refresh} from "@mui/icons-material";
import {NewsType} from "../../api/Api";
import {userName} from "../../utils/user";
import ReceiptProcessed from "./ReceiptProcessed";
import ReceiptRollback from "./ReceiptRollback";
import TransferRegistered from "./TransferRegistered";

const NewsPage: FC = () => {
    const dispatch = useAppDispatch()
    const news = useAppSelector(state => state.news.list)

    const [filter, setFilter] = useState('')

    useEffect(function () {
        if (!news.loaded) {
            dispatch(loadNews())
        }
    }, [])

    return <Container sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
    }} disableGutters>
        <Stack padding={2} spacing={2} direction="row">
            <SearchInput onFilterChange={(filter) => {
                setFilter(filter)
            }}/>
        </Stack>
        <Box sx={{
            width: '100%',
            maxHeight: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            boxSizing: 'border-box',
            height: 'inherit',
        }}>
            <Container>
                {
                    news.isProcessing &&
                    <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                        <CircularProgress/>
                    </Box>
                }
                {
                    news.error != null &&
                    <Alert severity="error" action={
                        <IconButton
                            aria-label="reload"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(loadNews())
                            }}
                        >
                            <Refresh fontSize="inherit"/>
                        </IconButton>
                    }>
                        {news.error}
                    </Alert>
                }
                {
                    !news.isProcessing && news.items.length > 0 &&
                    <Stack spacing={1} pb={2}>
                        {
                            news.items.map((newsItem) => {
                                if (newsItem.type === NewsType.TYPE_TRANSFER_REGISTERED) {
                                    const transfer = newsItem?.transferRegistered?.transfer ?? {}

                                    if (
                                        filter !== '' &&
                                        !(userName(transfer.sender ?? {}).toUpperCase().includes(filter.toUpperCase()) ||
                                            userName(transfer.recipient ?? {}).toUpperCase().includes(filter.toUpperCase()))
                                    ) {
                                        return null
                                    }

                                    return <TransferRegistered key={newsItem.id} transfer={transfer}/>
                                } else if (newsItem.type === NewsType.TYPE_RECEIPT_PROCESSED) {
                                    const receipt = newsItem?.receiptProcessed?.receipt ?? {}

                                    if (
                                        filter !== '' &&
                                        !((receipt?.title ?? '').toUpperCase().includes(filter.toUpperCase())
                                            // || userName(receipt?.author ?? {}).toUpperCase().includes(filter.toUpperCase())
                                        )
                                    ) {
                                        return null
                                    }

                                    return <ReceiptProcessed key={newsItem.id} news={newsItem}/>
                                } else if (newsItem.type === NewsType.TYPE_RECEIPT_ROLLBACK) {
                                    const receipt = newsItem?.receiptRollback?.receipt ?? {}

                                    if (
                                        filter !== '' &&
                                        !((receipt?.title ?? '').toUpperCase().includes(filter.toUpperCase())
                                            // || userName(receipt?.author ?? {}).toUpperCase().includes(filter.toUpperCase())
                                        )
                                    ) {
                                        return null
                                    }

                                    return <ReceiptRollback key={newsItem.id} news={newsItem}/>
                                }

                                return null
                            })
                        }
                    </Stack>
                }
            </Container>
        </Box>


    </Container>
}

export default NewsPage