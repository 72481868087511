import {GavialUser, GavialUserContact} from "../../api/Api";
import React, {FC} from "react";
import Typography from "@mui/material/Typography";
import {userName} from "../../utils/user";

interface UserNameProps {
    user: GavialUser | GavialUserContact
}

const UserName: FC<UserNameProps> = ({user}) => {
    return <Typography height='100%' overflow='auto' sx={{whiteSpace: 'nowrap'}} >
        {userName(user)}
    </Typography>
}

export default UserName