import React, {FC} from "react";
import {IconButton, Paper, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {userName} from "../../utils/user";
import {green, red} from "@mui/material/colors";
import {GavialUserBalance} from "../../api/Api";
import {Send} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {useAppDispatch} from "../../hook";
import {remindAboutDebt} from "../../store/balances";

interface UserBalanceRowProps {
    userBalance: GavialUserBalance
}

const UserBalanceRow: FC<UserBalanceRowProps> = ({userBalance}) => {
    const dispatch = useAppDispatch()

    let color: any = green[200]
    if ((userBalance.amount?.amount ?? 0) < 0) {
        color = red[200]
    }
    const navigate = useNavigate();

    return <Stack direction={'row'}
                  display='flex'
                  boxSizing={'border-box'}
                  overflow={'auto'}
                  spacing={0}
                  border={0}
    >
        <Paper
            variant='outlined'
            sx={{
                padding: 1,
                bgcolor: color,
                flexGrow: 1,
                boxSizing: 'border-box',
                overflow: 'auto',
            }}
        >
            <Stack alignItems={'center'} border={0} direction='row' display='flex' spacing={1} alignContent={'center'}
                   justifyContent={'center'}>
                <Typography flexGrow={1} overflow='auto' sx={{whiteSpace: 'nowrap'}}>
                    {userName(userBalance?.user ?? {})}
                </Typography>
                <Typography align='center' sx={{whiteSpace: 'nowrap'}}>
                    {userBalance?.amount?.amount} {userBalance?.amount?.currency}
                </Typography>
                {
                    userBalance?.remindAvailable &&
                    <IconButton hidden={(userBalance?.amount?.amount ?? 0) < 0} sx={{p: 0, mr: 2, ml: 2}}
                                onClick={function () {
                                    dispatch(remindAboutDebt(userBalance.user?.userId ?? ''))
                                }}>
                        <NotificationsActiveIcon/>
                    </IconButton>
                }
                <IconButton sx={{p: 0}}
                            onClick={() => {
                                navigate('/transfers/create', {
                                    state: {
                                        "backLinkTo": "/balances",
                                        "userBalance": userBalance
                                    },
                                })
                            }}
                >
                    <Send sx={{fontSize: 20}}/>
                </IconButton>
            </Stack>
        </Paper>
    </Stack>
}

export default UserBalanceRow