import React, {FC, useEffect, useState} from 'react';
import {Alert, Box, Button, CircularProgress, Container, IconButton, Stack} from "@mui/material";
import SearchInput from "../common/SearchInput";
import {Link, Route, Routes} from "react-router-dom";
import PageWithBackLink from "../common/PageWithBackLink";
import Typography from "@mui/material/Typography";
import TransferCreatePage from "./TransferCreatePage";
import {useAppDispatch, useAppSelector} from "../../hook";
import {loadTransfers} from "../../store/transfers";
import {Refresh} from "@mui/icons-material";
import {userName} from "../../utils/user";
import TransferItem from "./TransferItem";

const TransfersPage: FC = () => {
    const dispatch = useAppDispatch()
    const transfers = useAppSelector(state => state.transfers.list)

    const [filter, setFilter] = useState('')

    useEffect(function () {
        if (transfers.items.length === 0) {
            dispatch(loadTransfers())
        }
    }, [])

    return <Container sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
    }} disableGutters>
        <Routes>
            <Route index element={
                <>
                    <Stack padding={2} spacing={2} direction="row">
                        <SearchInput onFilterChange={(filter) => {
                            setFilter(filter)
                        }}/>
                        <Button component={Link} to="create" variant="contained" color="primary" sx={{ml: 2}}>
                            Создать
                        </Button>
                    </Stack>
                    <Box sx={{
                        width: '100%',
                        maxHeight: 'inherit',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        boxSizing: 'border-box',
                        height: 'inherit',
                    }}>
                        <Container>
                            {
                                transfers.isProcessing &&
                                <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                                    <CircularProgress/>
                                </Box>
                            }
                            {
                                transfers.error != null &&
                                <Alert severity="error" action={
                                    <IconButton
                                        aria-label="reload"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            dispatch(loadTransfers())
                                        }}
                                    >
                                        <Refresh fontSize="inherit"/>
                                    </IconButton>
                                }>
                                    {transfers.error}
                                </Alert>
                            }
                            {
                                !transfers.isProcessing && transfers.items.length > 0 &&
                                <Stack spacing={1} pb={2}>
                                    {transfers.items.map((transfer, i) => {
                                        if (
                                            filter !== '' &&
                                            !(userName(transfer.sender ?? {}).toUpperCase().includes(filter.toUpperCase()) ||
                                                userName(transfer.recipient ?? {}).toUpperCase().includes(filter.toUpperCase()))
                                        ) {
                                            return null
                                        }

                                        return <TransferItem key={i} transfer={transfer ?? {}}/>
                                    })}
                                </Stack>
                            }
                        </Container>
                    </Box>
                </>
            }/>
            <Route path="create/*" element={
                <TransferCreatePage/>
            }/>
        </Routes>
    </Container>
}

export default TransfersPage