import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hook";
import {loadReceipt, rollbackReceipt} from "../../store/receipts";
import {Alert, Box, CircularProgress, Container, Divider, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {userName} from "../../utils/user";
import {History, Payments, SocialDistance} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {ReceiptsStatus} from "../../api/Api";
import {blue, green} from "@mui/material/colors";

const ReceiptPage: FC = () => {
    const dispatch = useAppDispatch()
    const {id} = useParams();
    const receiptState = useAppSelector(state => state.receipts.detailed)
    const rollbackState = useAppSelector(state => state.receipts.rollback)
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const navigate = useNavigate();
    let receipt = receiptState.item ?? {};

    useEffect(() => {
        dispatch(loadReceipt(id ?? ''))
    }, [])

    useEffect(() => {
        if (rollbackState.item?.id === id) {
            navigate('/receipts/' + id)
        }
    }, [rollbackState.item])

    const processedAt = (new Date(receipt.processedAt ?? '')).toLocaleString("ru-RU", {
        timeZone: tz
    })

    return <Box sx={{
        width: '100%',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxSizing: 'border-box',
        height: 'inherit',
    }}>
        <Container sx={{
            width: '100%',
            maxHeight: 'inherit',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            boxSizing: 'border-box',
            height: 'inherit',
        }}>
            {
                receiptState.isProcessing &&
                <Box mt={2} justifyContent='center' flexGrow={1} sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box>
            }
            {
                !receiptState.isProcessing &&
                <Stack mt={1} spacing={1}>
                    <Stack>
                        <Typography variant="h6" align={'center'}>{receipt.title}</Typography>
                        {
                            receipt.status === ReceiptsStatus.STATUS_PROCESSED &&
                            <Typography color={green[800]} variant="body2"
                                        align={'center'}>{'обработан ' + processedAt}</Typography>
                        }
                        {
                            (receipt.status === ReceiptsStatus.STATUS_CREATED || receipt.status === ReceiptsStatus.STATUS_READY_TO_PROCESS) &&
                            <Typography color={blue[800]} variant="body2"
                                        align={'center'}>{'редактируется'}</Typography>
                        }
                        <Typography variant="body2" align={'center'}>{'автор ' + userName(receipt.author)}</Typography>
                    </Stack>
                    <Divider/>
                    <Stack>
                        {
                            receipt.positions?.map(position => {
                                const hasDiscount = position.amount?.amount !== position.discountedAmount?.amount

                                return <Stack key={position.id}>
                                    <Stack spacing={1} direction={'row'}>
                                        <Typography variant={'h6'}>
                                            {position.index + '. ' + position.title + ' '}
                                        </Typography>
                                        <Typography variant={'h6'}
                                                    sx={{textDecoration: hasDiscount ? "line-through" : null}}
                                        >
                                            {position.amount?.amount}
                                        </Typography>
                                        {
                                            hasDiscount &&
                                            <Typography variant={'h6'}>{position.discountedAmount?.amount}</Typography>
                                        }
                                        <Typography variant={'h6'}>{receipt.currency}</Typography>
                                    </Stack>
                                    <Stack ml={3}>
                                        {
                                            position.consumptions?.map(consumption => {
                                                return <Stack key={consumption.id} direction={'row'} spacing={1}>
                                                    <Stack flexGrow={1}
                                                           display={'flex'}
                                                           overflow={'auto'}
                                                           boxSizing={'border-box'}>
                                                        <Typography
                                                            fontWeight={consumption.user?.isMe ? 'bold' : 'standard'}
                                                            sx={{whiteSpace: 'nowrap'}}>
                                                            {userName(consumption.user) + ':'}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack>
                                                        <Typography sx={{whiteSpace: 'nowrap'}}>
                                                            {consumption.calculatedAmount?.amount + ' ' + receipt.currency}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            })
                                        }
                                    </Stack>
                                </Stack>
                            })
                        }
                    </Stack>
                    <Divider/>
                    <Stack>
                        {
                            receipt.payments?.map(payment => {
                                return <Stack key={payment.id} direction={'row'} spacing={1}>
                                    <Payments/>
                                    <Stack flexGrow={1}
                                           display={'flex'}
                                           overflow={'auto'}
                                           boxSizing={'border-box'}
                                    >
                                        <Typography
                                            fontWeight={payment.user?.isMe ? 'bold' : 'standard'}
                                            sx={{whiteSpace: 'nowrap'}}>
                                            {userName(payment.user)}
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography sx={{whiteSpace: 'nowrap'}}>
                                            {'оплатил ' + payment.amount?.amount + ' ' + receipt.currency}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            })
                        }
                    </Stack>
                    {/*<Divider/>*/}
                    <Stack>
                        {
                            receipt.debts?.map(debt => {
                                return <div key={debt.index}>
                                    <Divider/>
                                    <Stack mt={1} mb={1} key={debt.index} direction={'row'} spacing={1} display={'flex'}
                                           overflow={'auto'} boxSizing={'border-box'}
                                    >
                                        <SocialDistance sx={{mt: 0.3}}/>
                                        <Stack display={'flex'} overflow={'auto'} boxSizing={'border-box'}>
                                            <Stack flexGrow={1} display={'flex'} overflow={'auto'}
                                                   boxSizing={'border-box'}
                                            >
                                                <Typography
                                                    fontWeight={debt.debtor?.isMe ? 'bold' : 'standard'}
                                                    sx={{whiteSpace: 'nowrap'}}
                                                >
                                                    {userName(debt.debtor)}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={'row'} spacing={1}>
                                                <Typography>должен</Typography>
                                                <Typography>{debt.amount?.amount} {debt.amount?.currency}</Typography>
                                            </Stack>
                                            <Stack flexGrow={1} display={'flex'} overflow={'auto'}
                                                   boxSizing={'border-box'}
                                            >
                                                <Typography
                                                    fontWeight={debt.creditor?.isMe ? 'bold' : 'standard'}
                                                    sx={{whiteSpace: 'nowrap'}}
                                                >
                                                    {userName(debt.creditor)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </div>
                            })
                        }
                    </Stack>
                </Stack>
            }
        </Container>
        {
            receipt?.author?.isMe && receipt.status === ReceiptsStatus.STATUS_PROCESSED &&
            <>
                <Divider/>
                <Stack ml={2} mr={2} mt={2} flexGrow={1} spacing={1}>
                    {
                        rollbackState.error !== null &&
                        <Alert sx={{flex: 1}} severity="error">{rollbackState.error}</Alert>
                    }
                    <LoadingButton
                        loading={rollbackState.isProcessing}
                        loadingPosition='start'
                        color='error'
                        variant={'contained'}
                        startIcon={<History/>}
                        onClick={() => {
                            dispatch(
                                rollbackReceipt({
                                    id: receipt?.id,
                                    version: receipt?.version
                                })
                            )
                        }}>
                        Откатить
                    </LoadingButton>
                </Stack>
            </>
        }
    </Box>
}

export default ReceiptPage