import {GavialTransfer} from "../api/Api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "../apiClient/api";
import {loadTransfers} from "./transfers";

interface CurrencyState {
    loaded: boolean
    isProcessing: boolean
    error: string | null
    items: string[]
}

type DictsState = {
    currency: CurrencyState
}

const initialState: DictsState = {
    currency: {
        loaded: false,
        isProcessing: false,
        error: null,
        items: [],
    },
}

export const loadCurrency = createAsyncThunk<string[], undefined, { rejectValue: string }>(
    'loadCurrency',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.dict.serviceListCurrency()

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки валют')
            }

            return resp.data.items ?? []
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки валют')
        }
    }
)

const dictsSlice = createSlice({
    name: 'dicts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadCurrency.pending, (state) => {
                state.currency.isProcessing = true
                state.currency.error = null
            })
            .addCase(loadCurrency.fulfilled, (state, action) => {
                state.currency.isProcessing = false
                state.currency.items = action.payload
                state.currency.loaded = true
            })
            .addCase(loadCurrency.rejected, (state, action) => {
                state.currency.isProcessing = false
                state.currency.error = action.payload ?? null
            })
    }
})

export default dictsSlice.reducer;