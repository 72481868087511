import {GavialNews} from "../api/Api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createTransfer} from "./transfers";
import api from "../apiClient/api";
import {processReceipt, rollbackReceipt} from "./receipts";
import {updateUser} from "./user";

interface NewsListState {
    loaded: boolean
    isProcessing: boolean
    error: string | null
    items: GavialNews[]
}

type NewsState = {
    list: NewsListState
}

const initialState: NewsState = {
    list: {
        loaded: false,
        isProcessing: false,
        error: null,
        items: [],
    },
}

export const loadNews = createAsyncThunk<GavialNews[], undefined, { rejectValue: string }>(
    'loadNews',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.news.serviceListNews()

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки')
            }

            return resp.data.items ?? []
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки')
        }
    }
)

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadNews.pending, (state) => {
                state.list.isProcessing = true
                state.list.error = null
            })
            .addCase(loadNews.fulfilled, (state, action) => {
                state.list.isProcessing = false
                state.list.items = action.payload
                state.list.loaded = true
            })
            .addCase(loadNews.rejected, (state, action) => {
                state.list.isProcessing = false
                state.list.error = action.payload ?? null
            })
            .addCase(createTransfer.fulfilled, (state) => {
                clearState(state)
            })
            .addCase(processReceipt.fulfilled, (state) => {
                clearState(state)
            })
            .addCase(rollbackReceipt.fulfilled, (state) => {
                clearState(state)
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                clearState(state)
            })
    }
})

export default newsSlice.reducer;

function clearState(state: NewsState) {
    state.list.items = []
    state.list.loaded = false
}