import {GavialUserContact} from "../api/Api";

export function userName(user: GavialUserContact|undefined): string {
    if (user === undefined) {
        return ''
    }

    let userName

    if (
        (user.firstName ?? '') !== '' &&
        (user.lastName ?? '') !== ''
    ) {
        userName = user.firstName + ' ' + user.lastName
    } else if ((user.username ?? '') !== '') {
        userName = user.username ?? ''
    } else {
        userName = user.userId
    }

    if (user.isMe) {
        userName = userName + ' (я)'
    }

    return userName ?? 'Неизвестный'
}