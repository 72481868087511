import React, {FC} from "react";
import {GavialReceiptItem, ReceiptsStatus} from "../../api/Api";
import {Paper, Stack} from "@mui/material";
import {amber, green, grey} from "@mui/material/colors";
import {ReceiptLong} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {userName} from "../../utils/user";
import {useNavigate} from "react-router-dom";
import {NavigateOptions} from "react-router/dist/lib/context";

interface ReceiptItemProps {
    receipt: GavialReceiptItem
    backLink?: string
}

const ReceiptItem: FC<ReceiptItemProps> = ({receipt, backLink}) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const navigate = useNavigate();
    const ts = (new Date(receipt.updatedAt ?? '')).toLocaleString("ru-RU", {
        timeZone: tz
    })

    let color: any = grey[400]
    if (receipt.status === ReceiptsStatus.STATUS_READY_TO_PROCESS) {
        color = amber[200]
    } else if (receipt.status === ReceiptsStatus.STATUS_PROCESSED) {
        color = green[200]
    }

    return <Paper key={receipt.id} variant='outlined' sx={{padding: 1, bgcolor: color}} onClick={() => {
        let options: NavigateOptions = {}
        if (backLink) {
            options.state = {
                "backLinkTo": backLink,
            }
        }

        if (receipt.status === ReceiptsStatus.STATUS_PROCESSED) {
            navigate('/receipts/' + receipt.id + '/show', options)
        } else {
            navigate('/receipts/' + receipt.id, options)
        }
    }}>
        <Stack direction='row' spacing={0.5}>
            <ReceiptLong sx={{mt: 0.1}} fontSize={'small'}/>
            <Typography overflow='auto' sx={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
            }}>
                {receipt.title}
            </Typography>
        </Stack>
        <Typography overflow='auto' sx={{whiteSpace: 'nowrap'}}>
            {ts}
        </Typography>
        <Typography overflow='auto' sx={{whiteSpace: 'nowrap'}}>
            {statusToStr(receipt.status)}
        </Typography>
        <Typography overflow='auto' sx={{whiteSpace: 'nowrap'}}>
            {'автор: ' + userName(receipt?.author ?? {})}
        </Typography>
    </Paper>
}

export default ReceiptItem

function statusToStr(status: ReceiptsStatus|undefined): string {
    switch (status) {
        case ReceiptsStatus.STATUS_CREATED:
            return "редактируется"
        case ReceiptsStatus.STATUS_READY_TO_PROCESS:
            return "можно обработать"
        case ReceiptsStatus.STATUS_PROCESSED:
            return "обработан"
        default:
            return "неизвестный статус"
    }
}