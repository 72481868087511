import {GavialChangeMeRequest, GavialChangeMeResponse, GavialGetMeResponse, GavialUser} from "../api/Api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "../apiClient/api";

type UserInfoState = {
    loaded: boolean
    isLoading: boolean
    user: GavialUser | null
    currency: string | null
    error: string | null
}

type UserUpdateState = {
    isProcessing: boolean
    error: string | null
    success: boolean | null
}

type UserState = {
    info: UserInfoState
    update: UserUpdateState
}

const initialState: UserState = {
    info: {
        loaded: false,
        isLoading: false,
        user: null,
        currency: null,
        error: null,
    },
    update: {
        isProcessing: false,
        error: null,
        success: null,
    }
}

export const loadUser = createAsyncThunk<GavialGetMeResponse, undefined, { rejectValue: string }>(
    'loadUser',
    async function (_, {rejectWithValue}) {
        try {
            const resp = await api.users.serviceGetMe()

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки информации')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки информации')
        }
    }
)

export const updateUser = createAsyncThunk<GavialChangeMeResponse, GavialChangeMeRequest, { rejectValue: string }>(
    'updateUser',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.users.serviceChangeMe(request)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка сохранения')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка сохранения')
        }
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadUser.pending, (state) => {
                state.info.isLoading = true
                state.info.user = null
                state.info.error = null
            })
            .addCase(loadUser.fulfilled, (state, action) => {
                state.info.loaded = true
                state.info.isLoading = false
                state.info.user = action.payload.user ?? null
                state.info.currency = action.payload.currency ?? null
            })
            .addCase(loadUser.rejected, (state, action) => {
                state.info.isLoading = false
                state.info.error = action.payload ?? null
            })
            .addCase(updateUser.pending, (state) => {
                state.update.isProcessing = true
                state.update.error = null
                state.update.success = null
            })
            .addCase(updateUser.fulfilled, (state) => {
                state.update.isProcessing = false
                state.update.error = null
                state.update.success = true
                clearState(state)
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.update.isProcessing = false
                state.update.error = action.payload ?? null
                state.update.success = false
            })
    }
})

export default userSlice.reducer;

function clearState(state: UserState) {
    state.info.loaded = false
    state.info.isLoading = false
    state.info.user = null
    state.info.error = null
}