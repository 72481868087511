import {
    GavialCreateGroupRequest,
    GavialCreateGroupResponse,
    GavialGroup,
    GavialGroupDetailed,
    GavialGroupMember,
    GavialJoinGroupResponse, GavialLeaveGroupResponse,
} from "../api/Api";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "../apiClient/api";
import {loadContactDetailed} from "./contacts";
import {userName} from "../utils/user";

interface GroupCreationState {
    isProcessing: boolean
    error: string | null
    created: GavialGroup | null
}

interface GroupJoinState {
    isProcessing: boolean
    error: string | null
}

interface GroupLeaveState {
    isProcessing: boolean
    error: string | null
}

interface GroupListState {
    loaded: boolean
    isProcessing: boolean
    error: string | null
    items: GavialGroup[]
}

interface GroupDetailed {
    item: GavialGroupDetailed | null
    isLoading: boolean
    error: string | null
}

interface GroupDetailedListState {
    items: Record<string, GroupDetailed>
}

type GroupsState = {
    creation: GroupCreationState
    join: GroupJoinState
    leave: GroupLeaveState
    list: GroupListState
    detailed: GroupDetailedListState
}

const initialState: GroupsState = {
    creation: {
        isProcessing: false,
        error: null,
        created: null,
    },
    join: {
        isProcessing: false,
        error: null,
    },
    leave: {
        isProcessing: false,
        error: null,
    },
    list: {
        loaded: false,
        isProcessing: false,
        error: null,
        items: [],
    },
    detailed: {
        items: {}
    }
}

export const joinGroup = createAsyncThunk<GavialJoinGroupResponse, string, { rejectValue: string }>(
    'joinGroup',
    async function (groupId, {rejectWithValue}) {
        try {
            const resp = await api.groups.serviceJoinGroup(groupId)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка')
        }
    }
)

export const leaveGroup = createAsyncThunk<GavialLeaveGroupResponse, string, { rejectValue: string }>(
    'leaveGroup',
    async function (groupId, {rejectWithValue}) {
        try {
            const resp = await api.groups.serviceLeaveGroup(groupId)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка')
        }
    }
)

export const createGroup = createAsyncThunk<GavialCreateGroupResponse, GavialCreateGroupRequest, { rejectValue: string }>(
    'createGroup',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.groups.serviceCreateGroup(request)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка создания группы')
            }

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка создания группы')
        }
    }
)

export const loadGroups = createAsyncThunk<GavialGroup[], undefined, { rejectValue: string }>(
    'loadGroups',
    async function (request, {rejectWithValue}) {
        try {
            const resp = await api.groups.serviceListGroups()

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки групп')
            }

            return resp.data.items ?? []
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки групп')
        }
    }
)

export const loadGroupDetailed = createAsyncThunk<GavialGroupDetailed, string, { rejectValue: string }>(
    'loadContactDetailed',
    async function (id, {rejectWithValue}) {
        try {
            const resp = await api.groups.serviceGetGroupDetailed(id)

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка загрузки группы')
            }

            return resp.data.group ?? {}
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка загрузки группы')
        }
    }
)

const groupsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createGroup.pending, (state) => {
                state.creation.isProcessing = true
                state.creation.error = null
            })
            .addCase(createGroup.fulfilled, (state, action) => {
                clearState(state)
                state.creation.isProcessing = false
                state.creation.created = action.payload.group ?? {}
            })
            .addCase(createGroup.rejected, (state, action) => {
                state.creation.isProcessing = false
                state.creation.error = action.payload ?? null
            })
            .addCase(joinGroup.pending, (state) => {
                state.join.isProcessing = true
                state.join.error = null
            })
            .addCase(joinGroup.fulfilled, (state, action) => {
                clearState(state)
                state.join.isProcessing = false
            })
            .addCase(joinGroup.rejected, (state, action) => {
                state.join.isProcessing = false
                state.join.error = action.payload ?? null
            })
            .addCase(leaveGroup.pending, (state) => {
                state.leave.isProcessing = true
                state.leave.error = null
            })
            .addCase(leaveGroup.fulfilled, (state, action) => {
                clearState(state)
                state.leave.isProcessing = false
            })
            .addCase(leaveGroup.rejected, (state, action) => {
                state.leave.isProcessing = false
                state.leave.error = action.payload ?? null
            })
            .addCase(loadGroups.pending, (state) => {
                state.list.isProcessing = true
                state.list.error = null
            })
            .addCase(loadGroups.fulfilled, (state, action) => {
                state.list.isProcessing = false
                state.list.items = action.payload
                sortGroups(state.list.items)
                state.list.loaded = true
            })
            .addCase(loadGroups.rejected, (state, action) => {
                state.list.isProcessing = false
                state.list.error = action.payload ?? null
            })
            .addCase(loadGroupDetailed.pending, (state, action) => {
                state.detailed.items[action.meta.arg] = {
                    isLoading: true,
                    error: null,
                    item: null,
                }
            })
            .addCase(loadGroupDetailed.fulfilled, (state, action) => {
                state.detailed.items[action.payload.group?.id ?? ''] = {
                    isLoading: false,
                    error: null,
                    item: {
                        ...action.payload,
                        members: sortGroupMembers([...(action.payload.members ?? [])])
                    },
                }
            })
            .addCase(loadGroupDetailed.rejected, (state, action) => {
                state.detailed.items[action.meta.arg] = {
                    isLoading: false,
                    item: null,
                    error: action.payload ?? null,
                }
            })
    }
})

export default groupsSlice.reducer;

function clearState(state: GroupsState) {
    state.list.items = []
    state.list.loaded = false
    state.detailed.items = {}
}

function sortGroups(groups: GavialGroup[]) {
    return groups.sort((a, b) => (
        (a.title ?? '').toUpperCase() < (b.title ?? '').toUpperCase() ? -1 : 1)
    )
}

function sortGroupMembers(members: GavialGroupMember[]) {
    return members.sort((a, b) => (
        userName(a.contact ?? {}).toUpperCase() < userName(b.contact ?? {}).toUpperCase() ? -1 : 1)
    )
}