import React, {FC} from "react";
import {GavialNews} from "../../api/Api";
import {Paper, Stack} from "@mui/material";
import {green} from "@mui/material/colors";
import {ReceiptLong} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {userName} from "../../utils/user";
import {useNavigate} from "react-router-dom";
import {NavigateOptions} from "react-router/dist/lib/context";

interface ReceiptItemProps {
    news: GavialNews
}

const ReceiptProcessed: FC<ReceiptItemProps> = ({news}) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const navigate = useNavigate();
    const ts = (new Date(news.createdAt ?? '')).toLocaleString("ru-RU", {
        timeZone: tz
    })

    const color = green[200]

    return <Paper key={news.id} variant='outlined' sx={{padding: 1, bgcolor: color}} onClick={() => {
        let options: NavigateOptions = {
            state: {
                backLinkTo: "/feed",
            }
        }

        navigate('/receipts/' + news.receiptProcessed?.receipt?.id + '/show', options)
    }}>
        <Stack direction='row' spacing={0.5}>
            <ReceiptLong sx={{mt: 0.1}} fontSize={'small'}/>
            <Typography overflow='auto' sx={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
            }}>
                {news.receiptProcessed?.receipt?.title} обработан
            </Typography>
        </Stack>
        <Typography overflow='auto' sx={{whiteSpace: 'nowrap'}}>
            {ts}
        </Typography>
        <Typography overflow='auto' sx={{whiteSpace: 'nowrap'}}>
            {'автор: ' + userName(news.receiptProcessed?.receipt?.author ?? {})}
        </Typography>
    </Paper>
}

export default ReceiptProcessed