import React, {FC} from "react";
import {GavialTransfer} from "../../api/Api";
import {Paper, Stack} from "@mui/material";
import {green, lightGreen} from "@mui/material/colors";
import {Send, South} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {userName} from "../../utils/user";

interface TransferItemProps {
    transfer: GavialTransfer
}

const TransferItem: FC<TransferItemProps> = ({transfer}) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

    const ts = (new Date(transfer.createdAt ?? '')).toLocaleString("ru-RU", {
        timeZone: tz
    })

    const color = green[200]

    return <Paper key={transfer.id} variant='outlined' sx={{padding: 1, bgcolor: color}}>
        <Stack direction='row' spacing={0.5}>
            <Send fontSize={'small'}/>
            <Typography overflow='auto' sx={{whiteSpace: 'nowrap'}}>
                {ts}
            </Typography>
        </Stack>
        <Typography overflow='auto' sx={{
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
        }}>
            {userName(transfer?.sender ?? {})}
        </Typography>
        <Stack direction='row' spacing={0.5}>
            <South/>
            <Typography overflow='auto' sx={{whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                {transfer?.amount?.amount} {transfer?.amount?.currency}
            </Typography>
        </Stack>
        <Typography overflow='auto' sx={{
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
        }}>
            {userName(transfer?.recipient ?? {})}
        </Typography>
        <Typography overflow='auto' sx={{
            // whiteSpace: 'nowrap'
            wordWrap: 'break-word',
            WebkitLineClamp: 3,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
        }}>
            {transfer.description}
        </Typography>
    </Paper>
}

export default TransferItem