import React, {FC} from "react";
import {TLoginButton, TLoginButtonSize, TUser} from "react-telegram-auth";
import {Alert, Box, CircularProgress, IconButton, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useAppDispatch, useAppSelector} from "../../hook";
import {authenticate} from "../../store/auth";
import {Close} from "@mui/icons-material";
import {GavialTelegramAuthSource} from "../../api/Api";

const LoginPage: FC = () => {
    const dispatch = useAppDispatch()
    const auth = useAppSelector(state => state.auth)

    const [showError, setShowError] = React.useState(true);

    return <Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <Stack height="100%" direction="column" display={"flex"} spacing={0} alignItems={"center"}>
            <Box sx={{flex: 1}}></Box>
            <Box
                component="img"
                sx={{
                    height: 250,
                    width: 250,
                }}
                alt="Login"
                src="/money.png"
            />
            <Stack mt={10} spacing={2} alignItems={"center"}>
                <Typography textAlign={"center"} ml={2} mr={2} variant="h6">
                    Привет 👋<br/>
                    Чтобы продолжить, авторизуйся<br/>
                    с помощью Telegram 🔐
                </Typography>
                {
                    auth.isProcessing &&
                    <Box sx={{display: 'flex'}}>
                        <CircularProgress/>
                    </Box>
                }
                {
                    !auth.isProcessing &&
                    <TLoginButton
                        botName="MoniesSpaceBot"
                        buttonSize={TLoginButtonSize.Large}
                        lang="ru"
                        usePic={true}
                        cornerRadius={20}
                        onAuthCallback={(user) => {
                            dispatch(authenticate({
                                authData: getAuthData(user),
                                source: GavialTelegramAuthSource.TELEGRAM_AUTH_SOURCE_WIDGET,
                            }))
                            setShowError(true)
                        }}
                        requestAccess={'write'}
                    />
                }
                <Box minHeight={"50px"}>
                    {
                        showError && auth.error != null &&
                        <Alert severity="error" action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowError(false);
                                }}
                            >
                                <Close fontSize="inherit"/>
                            </IconButton>
                        }>{auth.error}</Alert>
                    }
                </Box>
            </Stack>
            <Box sx={{flex: 1}}></Box>
        </Stack>
    </Box>
}

export default LoginPage

function getAuthData(user: TUser): Record<string, string> {
    let authData: Record<string, string> = {
        "auth_date": String(user.auth_date),
        "first_name": user.first_name,
        "hash": user.hash,
        "id": String(user.id),
    }
    if (user.last_name != null) {
        authData["last_name"] = user.last_name
    }
    if (user.photo_url != null) {
        authData["photo_url"] = user.photo_url
    }
    if (user.username != null) {
        authData["username"] = user.username
    }

    return authData
}