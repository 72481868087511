import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {GavialAuthByTelegramRequest, GavialAuthByTelegramResponse, GavialTelegramAuthSource} from "../api/Api";
import api from "../apiClient/api";
import jwtDecode, {JwtPayload} from "jwt-decode";
import {TelegramWebApps} from "telegram-webapps-types";


const LOCAL_STORAGE_TOKEN_KEY = 'token'

type AuthState = {
    isRestoreEnd: boolean,
    isAuthenticated: boolean,
    isProcessing: boolean,
    error: string | null,
}

const initialState: AuthState = {
    isRestoreEnd: false,
    isAuthenticated: false,
    isProcessing: false,
    error: null,
}

export const authenticate = createAsyncThunk<GavialAuthByTelegramResponse, GavialAuthByTelegramRequest, {
    rejectValue: string
}>(
    'authenticate',
    async function (req, {rejectWithValue}) {
        try {
            const resp = await api.auth.serviceAuthByTelegram(req, {
                headers: {}
            })

            if (!resp.ok) {
                console.error(resp)
                return rejectWithValue('Ошибка авторизации')
            }

            const jwtPayload = jwtDecode<JwtPayload>(resp.data.token ?? '')
            api.setSecurityData({token: resp.data.token ?? '', exp: jwtPayload.exp ?? 0})
            localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, resp.data.token ?? '')

            return resp.data
        } catch (error) {
            console.error(error)
            return rejectWithValue('Ошибка авторизации');
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        tryRestoreAuthentication(state) {
            const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
            if (token != null) {
                const jwtPayload = jwtDecode<JwtPayload>(token)
                if (Date.now() / 1000 < (jwtPayload.exp ?? 0)) {
                    api.setSecurityData({token: token, exp: jwtPayload.exp ?? 0})
                    state.isAuthenticated = true
                }
            }

            state.isRestoreEnd = true
        },
        authFake(state) {
            // state.isAuthenticated = true
        },
        logout(state) {
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
            state.isAuthenticated = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authenticate.pending, (state) => {
                state.isProcessing = true
                state.error = null
            })
            .addCase(authenticate.fulfilled, (state, action) => {
                state.isProcessing = false
                state.isAuthenticated = true
            })
            .addCase(authenticate.rejected, (state, action) => {
                state.isProcessing = false
                state.error = action.payload ?? ""
            })
    }
})

export const {tryRestoreAuthentication, logout, authFake} = authSlice.actions

export default authSlice.reducer;